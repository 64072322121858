import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ProjectModel } from "src/app/project/Models/project-model";
import { ProjectService } from "src/app/project/project.service";
import { ProjectFileUploadComponent } from "../project-file-upload/project-file-upload.component";
import { environment } from "src/environments/environment";
import { LoaderService } from "src/app/loader/loader.service";
import { DlServiceService } from "src/app/dl-service.service";
@Component({
  selector: "app-project-info",
  templateUrl: "./project-info.component.html",
  styleUrls: ["./project-info.component.scss"],
})
export class ProjectInfoComponent implements OnInit {
  projectData: ProjectModel;
  sourceLanguages: any = [];
  documentType: any = [];
  fileFormat: any = [];
  priceDataInfo: any;

  constructor(
    private dialog: MatDialog,
    private projectService: ProjectService,
    private dlService: DlServiceService,
    private readonly loaderService: LoaderService
  ) {}

  public projectInfoGroupForm = new FormGroup({
    projectName: new FormControl("", [Validators.required]),
    sourceLanguage: new FormControl("", [Validators.required]),
    targetLanguage: new FormControl("", [Validators.required]),
    typeDoc: new FormControl("", [Validators.required]),
    sourceFileFormat: new FormControl("", [Validators.required]),
    targetFileFormat: new FormControl("", [Validators.required]),

    pricePerWord: new FormControl("", [Validators.required]),
    priceFileFormat: new FormControl("", [Validators.required]),
    priceDocType: new FormControl("", [Validators.required]),

    priceMinimum: new FormControl("", [Validators.required]),
    timePerWord: new FormControl("", [Validators.required]),
    timeMinimum: new FormControl("", [Validators.required]),

    sourceWordCount: new FormControl("", [
      Validators.required,
      Validators.pattern("^[0-9]+"),
    ]),
    sourcePageCount: new FormControl("", [
      Validators.required,
      Validators.pattern("^[0-9]+"),
    ]),
  });

  ngOnInit(): void {
    this.getRateEngineData();
  }

  public loadProject() {
    this.projectData = this.projectService.getCreateProjectInfo();
    if (this.projectData) {
      this.projectInfoGroupForm.patchValue({
        projectName: this.projectData.projectName,
        sourceLanguage: this.projectData.sourceLanguage,
        targetLanguage: this.projectData.targetLanguage,
        typeDoc: this.projectData.typeDoc,
        sourceFileFormat: this.projectData.sourceFileFormat,
        targetFileFormat: this.projectData.targetFileFormat,
        sourceWordCount: this.projectData.sourceWordCount,
        sourcePageCount: this.projectData.sourcePageCount,
        pricePerWord: this.projectData.pricePerWord,
        priceFileFormat: this.projectData.priceFileFormat,
        priceDocType: this.projectData.priceDocType,
        priceMinimum: parseFloat(this.priceDataInfo["a.minimum_price"]),
        timePerWord: parseFloat(this.priceDataInfo["a.time_per_word"]),
        timeMinimum: parseFloat(this.priceDataInfo["a.minimum_time"]),
      });

      this.projectData.priceMinimum = parseFloat(
        this.priceDataInfo["a.minimum_price"]
      );
      this.projectData.timePerWord = parseFloat(
        this.priceDataInfo["a.time_per_word"]
      );
      this.projectData.timeMinimum = parseFloat(
        this.priceDataInfo["a.minimum_time"]
      );
    } else {
      this.projectInfoGroupForm.patchValue({
        priceMinimum: parseFloat(this.priceDataInfo["a.minimum_price"]),
        timePerWord: parseFloat(this.priceDataInfo["a.time_per_word"]),
        timeMinimum: parseFloat(this.priceDataInfo["a.minimum_time"]),
      });
    }
  }

  public getRateEngineData(): void {
    this.priceDataInfo = this.projectService.getPriceData();
    if (this.priceDataInfo) {
      this.sourceLanguages = this.priceDataInfo["a.source_language"];
      this.documentType = this.priceDataInfo["a.document_type"];
      this.fileFormat = this.priceDataInfo["a.file_format"];
      this.loadProject();
    } else {
      const loaderSubscription = this.loaderService.spinner$.subscribe();
      this.dlService
        .getObject(environment.engineRateId)
        .subscribe((data: any) => {
          this.priceDataInfo = data;
          this.projectService.setPriceData(data);
          this.sourceLanguages = data["a.source_language"];
          this.documentType = data["a.document_type"];
          this.fileFormat = data["a.file_format"];
          this.loadProject();
          loaderSubscription.unsubscribe();
        });
    }
  }
  public bindLanguagePrice(_lang): void {
    const selectVal = this.sourceLanguages.filter((m: any) => m.lang === _lang);
    this.projectInfoGroupForm.patchValue({
      pricePerWord: selectVal[0]["price"],
    });
  }

  public bindDocumentTypePrice(_docType): void {
    const selectVal = this.documentType.filter((m: any) => m.type === _docType);
    this.projectInfoGroupForm.patchValue({
      priceDocType: selectVal[0]["price"],
    });
  }
  public bindFileFormatPrice(_fileFormat): void {
    const selectVal = this.fileFormat.filter(
      (m: any) => m.type === _fileFormat
    );
    this.projectInfoGroupForm.patchValue({
      priceFileFormat: selectVal[0]["price"],
    });
  }

  gotoNext() {
    if (this.projectData) {
      const formData = this.projectInfoGroupForm.value;

      this.projectData.projectName = formData.projectName;
      this.projectData.sourceLanguage = formData.sourceLanguage;
      this.projectData.targetLanguage = formData.targetLanguage;
      this.projectData.typeDoc = formData.typeDoc;
      this.projectData.sourceFileFormat = formData.sourceFileFormat;
      this.projectData.targetFileFormat = formData.targetFileFormat;
      this.projectData.sourceWordCount = formData.sourceWordCount;
      this.projectData.sourcePageCount = formData.sourcePageCount;

      this.projectData.pricePerWord = formData.pricePerWord;
      this.projectData.priceFileFormat = formData.priceFileFormat;
      this.projectData.priceDocType = formData.priceDocType;
      this.projectService.setCreateProjectInfo(this.projectData);
    } else {
      this.projectService.setCreateProjectInfo(this.projectInfoGroupForm.value);
    }

    this.dialog.open(ProjectFileUploadComponent, {
      width: "700px",
      disableClose: true,
    });
  }
}
