<mat-card class="shadow" style="padding: 15px 31px;margin-top:151px ;">
  <mat-card-header style="margin-bottom: 21px;">
    <mat-card-title style="margin-top: 16px;margin-bottom: 41px;">Login</mat-card-title>
    <mat-card-subtitle style="margin-bottom: 31px;">
      <img class="cls-img" src="assets/icon-png/logo-big.png" />
    </mat-card-subtitle>
  </mat-card-header>

  <form>
    <mat-card-content>
      <span class="font-weight-bold">Email</span>
      <mat-form-field appearance="outline" class="w-100">
        <mat-icon  style="background-image: url('assets/icon-png/email-icon.png');height: 13px;" matPrefix *ngIf="!email.value"></mat-icon>
        <input matInput placeholder="Enter your Email" [formControl]="email" required />
      </mat-form-field>

      <span class="font-weight-bold">Password</span>
      <mat-form-field appearance="outline" class="w-100">
        <mat-icon style="background-image: url('assets/icon-png/password-icon.png');height: 17px;" matPrefix *ngIf="!password.value"></mat-icon>
        <input matInput type="password"  placeholder="Enter Password" [formControl]="password" required />
        
      </mat-form-field>

      <span class="forget-password-link" aria-label="Forgot password?" routerLink="/auth/forgotPassword">
        Forgot password?
      </span>

    </mat-card-content>
    <button  mat-raised-button color="primary" class="w-100 login-btn" (click)="loginClick()"
      [disabled]="!password.valid || !email.valid">
      Login
    </button>
  </form>
</mat-card>