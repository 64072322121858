import { NgModule } from '@angular/core';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AuthModule } from '../auth/auth.module';
import { AuthGuard } from './auth-guard.service';
import { FoundryApiModule, IFoundryApiConfigModel } from "@thinglogix/ngx-foundry-core";
import { RoleGuard } from './role-guard.service';
const config: IFoundryApiConfigModel = {
    cognitoData: {
        foundryAccountId: environment.thinglogixRootAccountId,
        foundryApiGatewayEndpoint: environment.fndyThingLogixApiGatewayEndpoint,
        foundryCognitoIdentityPoolId: environment.fndyCognitoIdentityPool,
        foundryCognitoDeveloperProviderName: environment.fndyCognitoDeveloperProviderName
    }
};

@NgModule({
    imports: [
        AuthModule,
        NgbProgressbarModule,
        FoundryApiModule.forRoot(config)
    ],
    declarations: [],
    providers: [AuthGuard,RoleGuard, CookieService],
    exports: []
})
export class CoreModule {}
