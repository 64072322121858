import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public userData: any;
  public employeeData: any;
  public orgData: any;
  public customerServiceData: any;

  constructor() { }

  public getUserData() {
    return this.userData;
  }

  public setUserData(users): void {
    this.userData = users;
  }

  public getOrg() {
    return this.orgData;
  }

  public setOrg(org): void {
    this.orgData = org;
  }

  public getEmployeeData() {
    return this.employeeData;
  }

  public setEmployeeData(employees): void {
    this.employeeData = employees;
  }
  public getCustomerServiceData() {
    return this.customerServiceData;
  }

  public setCustomerServiceData(customers): void {
    this.customerServiceData = customers;
  }


}
