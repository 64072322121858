<mat-card class="shadow" style="padding: 15px 31px;margin-top:151px ;">
    <mat-card-header style="margin-bottom: 21px;">
      <mat-card-title style="margin-top: 16px;margin-bottom: 41px;width: 371px;">Activate User</mat-card-title>
      <mat-card-subtitle style="margin-bottom: 31px;">
        <img class="cls-img" src="assets/icon-png/logo-big.png" />
      </mat-card-subtitle>

      <mat-card-subtitle style="font-size: 17px;color: #7C828D;text-transform: capitalize;" class="pt-2">
      <span *ngIf ="loginDisabled">  Account activated successfully. Click below button to login.</span>
      </mat-card-subtitle>
    </mat-card-header>
  
    <form>

      <button style="height:50px ;margin-bottom: 121px;" mat-raised-button color="primary" class="w-100" (click)="loginClick()">
        Back to login
      </button>
    </form>
  </mat-card>