import { BrowserModule } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';

/* Routing */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/* Angular Material */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatDialogModule } from "@angular/material/dialog";

/* FormsModule */
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* Angular Flex Layout */
import { FlexLayoutModule } from "@angular/flex-layout";

/* Components */
import { NotifierModule } from "angular-notifier";
import { CoreModule } from "./core/core.module";
import { HomeModule } from "./home/home.module";
import { SplashScreenModule } from "./splash-screen/splash-screen.module";
import { LoaderComponent } from "./loader/loader.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoaderInterceptor } from "./loader/loader.interceptor";
import { DlServiceService } from './dl-service.service';
// import { DashboardComponent } from './project/dashboard/dashboard.component';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent
    // DashboardComponent
  ],
  imports: [
    CoreModule,
    HomeModule,
    BrowserModule,
    AppRoutingModule,
    SplashScreenModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MatDialogModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'middle',
          distance: 12
        }
      },
    }),
  ],
  providers: [
    CookieService, DlServiceService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule { }