import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { Observable, of } from "rxjs";
import { DlServiceService } from '../dl-service.service';
@Injectable({
    providedIn: 'root'
})

export class RoleGuard implements CanActivate, CanLoad {
    constructor(private router: Router,
        private dlServiceService: DlServiceService,) { }

    canActivate(_: any, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return of(this.checkURLRights(state.url));
    }
    canLoad(_: any, segments: UrlSegment[]): boolean | Observable<boolean> {
        const fullPath = segments.reduce((path, currentSegment) => {
            return `${path}/${currentSegment.path}`;
        }, '');
        return of(this.checkURLRights(fullPath));
    }

    private checkURLRights(fullPath: string): boolean {
        if (this.dlServiceService.showSuperUser()) {
            if (fullPath.includes('/users') &&
                !fullPath.includes('/users/individual')) {
                this.defaultUrl();
            }
        }
        else if (this.dlServiceService.showBackOffice()) {
            if (fullPath.includes('/rate-engine') ||
                fullPath.includes('/employee') ||
                fullPath.includes('/billing') ||
                fullPath.includes('/company') ||
                fullPath.includes('/users/individual') ||
                fullPath.includes('/users') ||
                fullPath.includes('/employee') ||
                fullPath.includes('/report/project') 
            ) {
                this.defaultUrl();
            }
        } else if (this.dlServiceService.showCustomerService()) {
            if ((fullPath.includes('/rate-engine') ||
                fullPath.includes('/employee')) ||
                (fullPath.includes('/users') &&
                    !fullPath.includes('/users/individual'))
            ) {
                this.defaultUrl();
            }
        } else if (this.dlServiceService.showIndividual()
            || this.dlServiceService.showCorporate()) {
            if (fullPath.includes('/rate-engine') ||
                fullPath.includes('/employee') ||
                fullPath.includes('/company') ||
                fullPath.includes('/users/individual') ||
                fullPath.includes('/employee') ||
                fullPath.includes('/report/project') ||
                fullPath.includes('/users')  ) {
                this.defaultUrl();
            }
        } else if (this.dlServiceService.showCorporateAdmin()) {
            if (fullPath.includes('/rate-engine') ||
                fullPath.includes('/employee') ||
                fullPath.includes('/company') ||
                fullPath.includes('/users/individual') ||
                fullPath.includes('/employee') ||
                fullPath.includes('/report/project')) {
                this.defaultUrl();
            }
        }
        return true;
    }
    private defaultUrl(): void {
        this.router.navigate(['/profile'], {
            queryParams: {},
        });
    }
}