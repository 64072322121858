<mat-toolbar class="header">
    <button mat-icon-button disableRipple class="logo">
        <img style="width: 160px;" [src]="logo" alt="" routerLink="/auth/login" routerLinkActive="active" />
    </button>
    <span class="action-header">
        <span class="toolbar-subtext login-info-text" *ngIf="btnType === 'login' || btnType.includes('login')">
            Don't have an account? <button class="btn-signup btn-font-600" routerLink="/auth/signup" routerLinkActive="active">Sign
                up</button>
        </span>
        <span class="toolbar-subtext login-info-text" *ngIf="!(btnType === 'login' || btnType.includes('login'))">
            Already have an account? <button class="btn-signup btn-font-600" routerLink="/auth/login"
                routerLinkActive="active">Sign In</button>
        </span>
    </span>
</mat-toolbar>
<div style="min-height: 600px;" >
    <div class="common-wrapper background-img1">
        <!-- <div class="background-img"></div> -->

        <div class="card-wrapper">
            <div class="row">
                <div class="col-lg-12">
                    <router-outlet></router-outlet>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div style="margin-top: 4.5rem;margin-bottom: 1.5rem;"
                        class="justify-content-center text-white font-weight-bold motto">
                        <div class="d-flex justify-content-center">
                            BUILD FASTER, SMARTER WITH THINGLOGIX
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>