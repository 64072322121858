import { NgModule } from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import { AuthGuard } from "./core/auth-guard.service";

const routes: Routes = [
    {
        canActivate: [AuthGuard],
        path: '',
        loadChildren: './home/home.module#HomeModule'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
