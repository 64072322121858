<mat-icon class="text-center w-100">check_circle_outline</mat-icon>
<h2 mat-dialog-title class="text-center w-100">{{title}}</h2>
<mat-dialog-content>
    <ng-container>
        <div class="text-size">
            <span class="w-100">{{description}}</span>
        </div>

        <button *ngIf ="routeRedirect" mat-raised-button class="btn-height" mat-dialog-close color="primary" routerLink="{{routeRedirect}}">OK</button>
        <button *ngIf ="!routeRedirect" mat-raised-button class="btn-height" mat-dialog-close color="primary" >OK</button>
    </ng-container>
</mat-dialog-content>
