import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatSidenav } from "@angular/material/sidenav";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import { AuthService, IFndyUser } from "@thinglogix/ngx-foundry-core";
import { fromEvent, Observable, ReplaySubject, Subscription } from "rxjs";
import { filter } from 'rxjs/operators';
import { takeUntil } from "rxjs/operators";
import { DlServiceService } from '../dl-service.service';
import { ProjectInfoComponent } from '../project/dashboard/modals/project-info/project-info.component';
import { MatDialog } from '@angular/material/dialog';
import { ProjectService } from '../project/project.service';
import { SelectCompanyComponent } from '../project/dashboard/modals/select-company/select-company.component';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  private ngUnsubscribe: ReplaySubject<any> = new ReplaySubject<any>(1);
  private resizeObservable$: Observable<Event>
  public searchVal: string;
  public showMember = false;
  public showCS = false;
  public showSuper = false;
  search: FormControl = new FormControl('', []);
  leftMenuList = [
    {
      index: 0,
      name: "Create Project",
      show: true,
    },
    {
      index: 1,
      name: "Folder",
      show: true,
    },
    {
      index: 2,
      name: "Messages",
      show: true,
    },
    {
      index: 3,
      name: "Notifications",
      show: true,
    },
    {
      index: 4,
      name: "Billing",
      show: true,
    },
    {
      index: 5,
      name: " Settings",
      show: true,
    },
    {
      index: 6,
      name: "Profile",
      show: true,
    },
    {
      index: 7,
      name: "Member",
      show: true,
    },
    {
      index: 8,
      name: "Company",
      show: true,
    },
    {
      index: 9,
      name: "Individual",
      show: true,
    },
    {
      index: 10,
      name: "Employees",
      show: true,
    },
    {
      index: 11,
      name: "Report",
      show: true,
    },
    {
      index: 12,
      name: "rate",
      show: true,
    }
    

  ];

  // isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  isMobile = () => window.innerWidth < 900;
  isPublic: boolean
  menuHover: boolean;
  user: IFndyUser;
  avatar: string;
  opened = false;
  showSubMenuManager = false;
  activeLink: string = '';
  userRole: string;
  backOfficeUser = true;
  constructor(
    private projectService: ProjectService,
    public router: Router,
    public dlServiceService: DlServiceService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {
    this.user = authService.user;
    this.avatar = `${this.user.first_name[0]}${this.user.last_name[0]}`.toUpperCase();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.activeLink = this.router.url;
    });
  }

  ngOnInit() {
    this.backOfficeUser = !this.dlServiceService.showBackOffice();
    this.userRole = this.dlServiceService.userRoles.toString();
    this.showCS = this.dlServiceService.showCustomerService();
    this.showSuper = this.dlServiceService.showSuperUser();
    this.showMember = this.dlServiceService.showCorporateAdmin();
    // if (!this.isMobile()) {
    this.opened = false;
    // }
    this.router.events
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (this.isMobile()) {
            this.opened = false;
            this.menuHover = false;
          }
        }
      })

    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeObservable$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(evt => {
        if (this.isMobile()) {
          this.sidenav.close();
        }
      })
  }



  signOut(): void {
    localStorage.removeItem('userInfo')
    this.authService.signOutUser();
    this.router.navigate(['/auth/login']);
    // To clear all instance of class
    location.reload();
  }

  public searchMenu(): void {
    if (this.searchVal) {
      this.showSubMenuManager = true;
      const value = this.searchVal.toLowerCase();
      let findMenu = this.leftMenuList;
      findMenu = findMenu.filter((m: any) =>
        m.name.toLowerCase().includes(value)
      );
      this.leftMenuList.forEach((element) => {
        element.show = false;
      });

      findMenu.forEach((element) => {
        this.leftMenuList.filter(
          (m: any) => m.name.toLowerCase() === element.name.toLowerCase()
        )[0].show = true;
      });
    } else {
      this.showSubMenuManager = false;
      // if (window.location.href.includes("/solution/")) {
      //   this.solutionSubMenu = true;
      // } else {
      //   this.solutionSubMenu = false;
      // }
      this.leftMenuList.forEach((element) => {
        element.show = true;
      });
    }
  }

  toggleSideNav(): void {
    if (this.isMobile()) {
      this.menuHover = !this.menuHover;
    } else {
      this.sidenav.toggle();
      this.menuHover = false;
    }
  }

  createProject(): void {
    if (this.dlServiceService.showCustomer()) {
      this.projectService.setCreateProjectInfo(null);
      this.dialog.open(ProjectInfoComponent, {
        width: '651px',
        disableClose: true
      });
    } else {
      this.projectService.setCreateProjectInfo(null);
      this.dialog.open(SelectCompanyComponent, {
        width: '451px',
        disableClose: true
      });
    }
  }

}
