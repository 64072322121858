import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from "@thinglogix/ngx-foundry-core";

@Injectable()
export class AlreadyAuthenticatedGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}
    canActivate(): boolean {
        if (
            this.authService.user &&
            window.location.pathname !== '/auth/activateUser' &&
            window.location.pathname !== '/auth/resetPassword'
        ) {
            this.router.navigateByUrl('');
            return false;
        }
        return true;
    }
}
