<mat-sidenav-container autosize hasBackdrop="false" role="navigation">
    <mat-sidenav #sidenav class="col-xl-3 col-lg-3 col-sm-6 col-md-4 overflow-hidden" mode="side" [(opened)]="opened">
        <div id="div_open" *ngIf="!sidenav.opened">
            <mat-toolbar>
                <button mat-icon-button disableRipple>
                    <span style="padding-left: 15px;" class="mat-button-wrapper">
                        <span class="logo" (click)="router.navigate(['/'])">
                            <img style="width: 103px;height: 75px;" src="assets/icon-png/logo-small.png" />
                        </span>
                        <mat-icon style="vertical-align: top;height: 70px;" svgIcon="arrow-d-l" aria-hidden="false"
                            (click)="sidenav.toggle()"></mat-icon>
                    </span>
                </button>
                <header></header>
            </mat-toolbar>
            <div class="catagories-heading-search">
                <input type="text" [(ngModel)]="searchVal" placeholder="Search" (ngModelChange)="searchMenu()"
                    autocomplete="off" />
                <img src="assets/icons/search-icon.svg" alt="" />
            </div>

            <div id="div_left_menu" class="left_menu">


                <mat-option *ngIf="leftMenuList[0].show && backOfficeUser" id="desktopMenu" class="menu-space" value="side"
                    (click)="createProject()">
                    <img class="img-icon" src="assets/icon-png/menu-proj-create.png" alt="" />
                    Create Project
                </mat-option>

                <mat-option *ngIf="leftMenuList[1].show" class="menu-space" value="side"
                    [routerLinkActiveOptions]="{exact:false}" routerLink="/folders" routerLinkActive="active">
                    <img *ngIf="activeLink !== '/folders'" class="img-icon" src="assets/icon-png/menu-folder.png"
                        alt="" />
                    <img *ngIf="activeLink == '/folders'" class="img-icon" src="assets/icon-png/folder-menu-blue.png"
                        alt="" />
                    Folder
                </mat-option>

                <mat-option *ngIf="leftMenuList[2].show" class="menu-space" value="side"
                    [routerLinkActiveOptions]="{exact:false}" routerLink="/messages" routerLinkActive="active">
                    <img *ngIf="activeLink !== '/messages'" class="img-icon" src="assets/icon-png/menu-message.png"
                        alt="" />
                    <img *ngIf="activeLink == '/messages'" class="img-icon" src="assets/icon-png/message-menu-blue.png"
                        alt="" />
                    Messages
                </mat-option>
                <mat-option *ngIf="leftMenuList[3].show" class="menu-space" value="side"
                    [routerLinkActiveOptions]="{exact:false}" routerLink="/notification" routerLinkActive="active">
                    <img *ngIf="activeLink !== '/notification'" class="img-icon"
                        src="assets/icon-png/menu-notification.png" alt="" />
                    <img *ngIf="activeLink == '/notification'" class="img-icon"
                        src="assets/icon-png/notification-menu-blue.png" alt="" />
                    Notifications
                </mat-option>
                <mat-option *ngIf="leftMenuList[4].show && backOfficeUser" class="menu-space" value="side"
                    [routerLinkActiveOptions]="{exact:false}" routerLink="/billing" routerLinkActive="active">
                    <img *ngIf="activeLink !== '/billing'" class="img-icon" src="assets/icon-png/menu-billing.png"
                        alt="" />
                    <img *ngIf="activeLink == '/billing'" class="img-icon" src="assets/icon-png/billing-menu-blue.png"
                        alt="" />
                    Billing
                </mat-option>


                <mat-list-item *ngIf="leftMenuList[6].show" (click)="showSubMenuManager = !showSubMenuManager">
                    <span class="menu1">
                        <img class="img-icon" src="assets/icon-png/menu-settings.png" alt="" />
                        <!-- <img *ngIf="showSubMenuManager" class="img-icon" src="assets/icon-png/setting-blue-menu.png" alt="" /> -->
                        Settings</span>
                </mat-list-item>

                <div class="submenu" *ngIf="showSubMenuManager">
                    <mat-option *ngIf="leftMenuList[6].show" style="padding-left:45px ;" value="side"
                        [routerLinkActiveOptions]="{exact:false}" routerLink="/profile/edit" routerLinkActive="active">
                        <img *ngIf="activeLink !== '/profile/edit'" class="img-icon"
                            src="assets/icon-png/profile-menu-grey.png" alt="" />
                        <img *ngIf="activeLink == '/profile/edit'" class="img-icon"
                            src="assets/icon-png/profile-menu-blue.png" alt="" />
                        Profile
                    </mat-option>
                    <mat-option *ngIf="leftMenuList[7].show && (showMember)" style="padding-left:45px ;"
                        value="side" [routerLinkActiveOptions]="{exact:false}" routerLink="/users"
                        routerLinkActive="active">
                        <img *ngIf="activeLink !== '/users'" class="img-icon" src="assets/icon-png/group-icon.png"
                            alt="" />
                        <img *ngIf="activeLink == '/users'" class="img-icon" src="assets/icon-png/group-icon-blue.png"
                            alt="" />
                        Member
                    </mat-option>

                    <mat-option *ngIf="leftMenuList[8].show && (showCS || showSuper)" style="padding-left:45px ;" value="side"
                        [routerLinkActiveOptions]="{exact:false}" routerLink="/company" routerLinkActive="active">
                        <img *ngIf="activeLink !== '/company'" class="img-icon"
                            src="assets/icon-png/company-icon-menu.png" alt="" />
                        <img *ngIf="activeLink == '/company'" class="img-icon"
                            src="assets/icon-png/company-blue-icon.png" alt="" />
                        Companies
                    </mat-option>

                    <mat-option *ngIf="leftMenuList[9].show && (showCS || showSuper)" style="padding-left:45px ;" value="side"
                        [routerLinkActiveOptions]="{exact:false}" routerLink="/users/individual" routerLinkActive="active">
                        <img *ngIf="activeLink !== '/users/individual'" class="img-icon"
                            src="assets/icon-png/individual-icon.png" alt="" />
                        <img *ngIf="activeLink == '/users/individual'" class="img-icon"
                            src="assets/icon-png/individual-blue-icon.png" alt="" />
                        Individuals
                    </mat-option>
                    <mat-option *ngIf="leftMenuList[10].show && showSuper" style="padding-left:45px ;" value="side"
                    [routerLinkActiveOptions]="{exact:false}" routerLink="/employee" routerLinkActive="active">
                    <img *ngIf="activeLink !== '/employee'" class="img-icon"
                        src="assets/icon-png/emp-icon-grey.png" alt="" />
                    <img *ngIf="activeLink == '/employee'" class="img-icon"
                        src="assets/icon-png/emp-icon-blue.png" alt="" />
                        Employees
                </mat-option>
                    <mat-option *ngIf="leftMenuList[11].show && (showCS || showSuper)" style="padding-left:45px ;" value="side"
                        [routerLinkActiveOptions]="{exact:false}" routerLink="/report/project" routerLinkActive="active">
                        <img *ngIf="activeLink !== '/report/project'" class="img-icon"
                            src="assets/icon-png/report-icon.png" alt="" />
                        <img *ngIf="activeLink == '/report/project'" class="img-icon"
                            src="assets/icon-png/report-blue-icon.png" alt="" />
                        Report
                    </mat-option>
                    <mat-option *ngIf="leftMenuList[12].show && showSuper" style="padding-left:45px ;" value="side"
                        [routerLinkActiveOptions]="{exact:false}" routerLink="/rate-engine" routerLinkActive="active">
                        <img *ngIf="activeLink !== '/rate-engine'" class="img-icon"
                            src="assets/icon-png/rate-engine-cost.png" alt="" />
                        <img *ngIf="activeLink == '/rate-engine'" class="img-icon"
                            src="assets/icon-png/rate-engine-cost-color.png" alt="" />
                        Rating Engine
                    </mat-option>

                </div>
            </div>
            <div class="profile-option">
                <div class="profile-info">
                    <div class="avatar">
                        {{avatar}}
                    </div>
                    <div class="user-name">
                        {{user.first_name}} {{user.last_name}} <br>
                        <span class="user-role"> {{userRole}}</span>
                    </div>
                    <img (click)="signOut()" class="logout-icon" src="assets/icon-png/logout-icon.png" alt="" />
                </div>
            </div>
        </div>
        <div id="div_Close" style="width: 60px;display:block;float: left;" *ngIf="sidenav.opened">
            <div class="icon-small-menu">
                <img (click)="sidenav.toggle()" src="/assets/icon-png/small-logo.png" />
            </div>
            <div id="div_left_menu_small" class="div_left_menu_small">

                <div class="icon-small-menu">
                    <img (click)="sidenav.toggle()" src="assets/icon-png/search_big.png" alt="" />
                </div>

                <div *ngIf="leftMenuList[0].show && backOfficeUser" class="icon-small-menu ">
                    <a matTooltipClass="right" matTooltipPosition="right" matTooltip="Create Project"
                         href="javascript:void('0')" (click)="createProject()">
                        <img src="assets/icon-png/menu-proj-create.png" alt="" />
                    </a>
                </div>

                <div class="icon-small-menu">
                    <a matTooltipClass="right" matTooltipPosition="right" matTooltip="Folder"
                        *ngIf="leftMenuList[1].show" href="javascript:void('0')" routerLink="/folders"
                        routerLinkActive="active">
                        <img *ngIf="activeLink != '/folders'" class="img-icon1" src="assets/icon-png/menu-folder.png"
                            alt="" />
                        <img *ngIf="activeLink == '/folders'" class="img-icon1"
                            src="assets/icon-png/folder-menu-blue.png" alt="" />
                    </a>

                </div>

                <div class="icon-small-menu">
                    <a matTooltipClass="right" matTooltipPosition="right" matTooltip="Messages"
                        *ngIf="leftMenuList[2].show" href="javascript:void('0')" routerLink="/messages"
                        routerLinkActive="active">
                        <img *ngIf="activeLink !== '/messages'" class="img-icon1" src="assets/icon-png/menu-message.png"
                            alt="" />
                        <img *ngIf="activeLink == '/messages'" class="img-icon1"
                            src="assets/icon-png/message-menu-blue.png" alt="" />
                    </a>
                </div>

                <div *ngIf="leftMenuList[3].show" class="icon-small-menu">
                    <a matTooltipClass="right" matTooltipPosition="right" matTooltip="Notifications"
                        href="javascript:void('0')" routerLink="/notification" routerLinkActive="active">
                        <img *ngIf="activeLink !== '/notification'" class="img-icon1"
                            src="assets/icon-png/menu-notification.png" alt="" />
                        <img *ngIf="activeLink == '/notification'" class="img-icon1"
                            src="assets/icon-png/notification-menu-blue.png" alt="" />
                    </a>
                </div>

                <div *ngIf="leftMenuList[4].show && backOfficeUser" class="icon-small-menu">
                    <a matTooltipClass="right" matTooltipPosition="right" matTooltip="Billing"
                         href="javascript:void('0')" routerLink="/billing"
                        routerLinkActive="active">
                        <img *ngIf="activeLink !== '/billing'" class="img-icon1" src="assets/icon-png/menu-billing.png"
                            alt="" />
                        <img *ngIf="activeLink == '/billing'" class="img-icon1"
                            src="assets/icon-png/billing-menu-blue.png" alt="" />
                    </a>
                </div>

                <div class="icon-small-menu">
                    <a class="tooltip-submenu" matTooltipClass="right" href="javascript:void('0')">
                        <div [ngClass]="{'tooltiptext-submenu' : true, 'top-margin' : showCS }">
                            <div class="tooltip-inner-box">
                                <div  class="icon-sub-menu">
                                    <a matTooltipClass="right" matTooltipPosition="right" matTooltip="Profile"
                                        href="javascript:void('0')" routerLink="/profile/edit"
                                        routerLinkActive="active">
                                        <img class="img-icon2" src="assets/icon-png/profile-menu-grey.png" alt="" />
                                    </a>
                                </div>
                                <div *ngIf="showMember" class="icon-sub-menu">
                                    <a matTooltipClass="right" matTooltipPosition="right" matTooltip="Member"
                                        href="javascript:void('0')" routerLink="/users"
                                        routerLinkActive="active">
                                        <img class="img-icon2" src="assets/icon-png/group-icon.png" alt="" />
                                    </a>
                                </div>

                                <div *ngIf="showCS || showSuper" class="icon-sub-menu">
                                    <a matTooltipClass="right" matTooltipPosition="right" matTooltip="Companies"
                                        href="javascript:void('0')" routerLink="/company"
                                        routerLinkActive="active">
                                        <img class="img-icon2" src="assets/icon-png/company-icon-menu.png" alt="" />
                                    </a>
                                </div>
                                <div *ngIf="showCS || showSuper" class="icon-sub-menu">
                                    <a matTooltipClass="right" matTooltipPosition="right" matTooltip="Individuals"
                                        href="javascript:void('0')" routerLink="/users/individual"
                                        routerLinkActive="active">
                                        <img class="img-icon2" src="assets/icon-png/individual-icon.png" alt="" />
                                    </a>
                                </div>

                                <div *ngIf="showSuper" class="icon-sub-menu">
                                    <a matTooltipClass="right" matTooltipPosition="right" matTooltip="Employees"
                                        href="javascript:void('0')" routerLink="/employee"
                                        routerLinkActive="active">
                                        <img class="img-icon2" src="assets/icon-png/emp-icon-grey.png" alt="" />
                                    </a>
                                </div>
                                
                                <div *ngIf="(showCS || showSuper)" class="icon-sub-menu">
                                    <a matTooltipClass="right" matTooltipPosition="right" matTooltip="Report"
                                        href="javascript:void('0')" routerLink="/report/project"
                                        routerLinkActive="active">
                                        <img class="img-icon2" src="assets/icon-png/report-icon.png" alt="" />
                                    </a>
                                </div>

                                <div *ngIf="showSuper" class="icon-sub-menu">
                                    <a matTooltipClass="right" matTooltipPosition="right" matTooltip="Rating Engine"
                                        href="javascript:void('0')" routerLink="/rate-engine"
                                        routerLinkActive="active">
                                        <img class="img-icon2" src="/assets/icons/rate-engine-cost.svg" alt="" />
                                    </a>
                                </div>
                                <div class="icon-sub-menu">
                                    <img (click)="sidenav.toggle()" class="img-icon2"
                                        src="assets/icon-png/arrow-left.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <img src="assets/icon-png/menu-settings.png" class="img-icon1" alt="" />
                    </a>

                </div>
            </div>
        </div>
    </mat-sidenav>

    <mat-sidenav-content [ngClass]="{'close-bar' : !sidenav.opened}">
        <div id="page-section" class="page-left">
            <div class="page-row">
                <app-header></app-header>
            </div>
            <div class="page-row">
                <router-outlet></router-outlet>
            </div>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>