<h2 mat-dialog-title>Specialized Translation Project
    <img src="/assets/icon-png/cross-icon-1.png" mat-dialog-close style="width: 11px;float: right;"  >
</h2>
<ng-container [formGroup]="projectInfoGroupForm">
    <div class="row">
        <div class="col-lg-12">
            <span class="dialog-subtitle">01. General Information</span>
            </div>
            </div>
    <div class="row">
        <div class="col-lg-6">
            <span class="input-label">Project Name</span>
            <mat-form-field appearance="outline" class="input-width" >
                <input matInput formControlName="projectName" placeholder="Enter Project Name" autocomplete="off">
            </mat-form-field>    
        </div>
        <div class="col-lg-6">
            <span class="input-label">Project Type</span>
            
            <mat-form-field appearance="outline" class="input-width" >
                <mat-select (selectionChange)="bindDocumentTypePrice($event.value)" MatFormFieldControl formControlName="typeDoc" placeholder="Select Document">
                    <mat-option *ngFor="let docs of documentType" [value]="docs['type']">
                        {{docs['type']}} </mat-option>
                </mat-select>
             
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6">
            <span class="input-label">Source Language</span>
            <mat-form-field class="input-width" appearance="outline">
                <mat-select (selectionChange)="bindLanguagePrice($event.value)" MatFormFieldControl formControlName="sourceLanguage"
                 placeholder="Select Language">
                    <mat-option *ngFor="let lang of sourceLanguages" [value]="lang['lang']">
                        {{lang['lang']}} </mat-option>
                </mat-select>
               
            </mat-form-field>
        </div>
        <div class="col-lg-6">
            <span class="input-label">Target Language</span>
        <mat-form-field class="input-width" appearance="outline">
            <mat-select  MatFormFieldControl
                formControlName="targetLanguage" placeholder="Select Language">
                <mat-option *ngFor="let lang of sourceLanguages" [value]="lang['lang']">
                    {{lang['lang']}} </mat-option>
            </mat-select>
        </mat-form-field>
       
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6">
            <span class="input-label">Source File Type</span>
            <mat-form-field appearance="outline" class="input-width">
                <mat-select (selectionChange)="bindFileFormatPrice($event.value)" MatFormFieldControl
                    formControlName="sourceFileFormat" placeholder="Select file format">
                    <mat-option *ngFor="let docs of fileFormat" [value]="docs['type']">
                        {{docs['type']}} </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-lg-6">
            <span class="input-label">Target File Type</span>
        <mat-form-field appearance="outline" class="input-width">
            <mat-select MatFormFieldControl formControlName="targetFileFormat" placeholder="Select file format">
                <mat-option *ngFor="let docs of fileFormat" [value]="docs['type']">
                    {{docs['type']}} </mat-option>
            </mat-select>
        </mat-form-field> 
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6">
            <span class="input-label">Number Of Pages</span>
            <mat-form-field appearance="outline" class="input-width" >
                <input maxlength="5" matInput formControlName="sourcePageCount" placeholder="Enter Number Of Pages" autocomplete="off">
            </mat-form-field>  
        </div>
        <div class="col-lg-6">
            <span class="input-label">Number Of Words</span>
            <mat-form-field appearance="outline" class="input-width" >
                <input maxlength="10" matInput formControlName="sourceWordCount" placeholder="Enter Number Of Words" autocomplete="off">
            </mat-form-field>    
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12" style="text-align: right; padding-right:9% ;" >
            <button mat-stroked-button mat-button mat-dialog-close color="primary">Cancel</button>
            <button style="margin-left: 11px;" mat-raised-button color="primary" mat-dialog-close cdkFocusInitial [disabled]="!projectInfoGroupForm.valid"
                (click)="gotoNext()">Next</button>
               
        </div>
    </div>
</ng-container>
