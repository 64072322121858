<div style="overflow-y:auto;height:100vh ;" [formGroup]="userForm">
    <div class="row">
        <div class="col-md-12 txt-title">
            Add User Or Company
        </div>
    </div>

    <div class="row">
        <mat-card-content style="width:100% ;margin-bottom: 30px;">
            <mat-tab-group (selectedTabChange)="accountChangeEvent($event)" animationDuration="0ms">
                <mat-tab style="opacity:1 ;" label="Individual">
                    <ng-template mat-tab-label>
                        <div>
                            <img *ngIf="accountType === 'Individual'" style="margin-bottom: 3px;" src="assets/icon-png/user-icon-blue.png" />
                            <img *ngIf="accountType !== 'Individual'" style="margin-bottom: 3px;" src="assets/icon-png/signup-i-n.png" />
                            <span
                                [ngClass]="{ 'tab-select': accountType === 'Individual', 'tab-unselect': accountType !== 'Individual' }">
                                Individual
                            </span>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Corporate">
                    <ng-template mat-tab-label>
                        <div>
                            <img *ngIf="accountType === 'Individual'" src="assets/icon-png/sign-c-n.png" />
                            <img *ngIf="accountType !== 'Individual'" src="assets/icon-png/coporate-icon-grey.png" />
                            <span
                                [ngClass]="{ 'tab-select': accountType !== 'Individual', 'tab-unselect': accountType === 'Individual' }">
                                Corporate </span>
                        </div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </div>
    <div   [@toggleBox]="flagToggleBox" class="row">
        <div class="col-md-12">
                <div  >
                <span class="input-label d-flex">Company Name</span>
                <div class="select-box-outer">
                    <div class="select-box-div">
                        <img src="assets/icon-png/user-icon.png" class="icon-mat" />
                        <input class="input-text" placeholder="Enter Company Name" type="text"
                            formControlName="companyName" required />
                    </div>
                </div>
            </div>
        </div>
    </div>
 
    <div class="row">
        <div class="col-md-6">
            <span class="input-label d-flex">First Name</span>
            <div class="select-box-outer">
                <div class="select-box-div">
                    <img src="assets/icon-png/user-icon.png" class="icon-mat" />
                    <input class="input-text" placeholder="Enter First Name" type="text" formControlName="firstName"
                        required />
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <span class="input-label d-flex">Last Name</span>
            <div class="select-box-outer">
                <div class="select-box-div">
                    <img src="assets/icon-png/user-icon.png" class="icon-mat" />
                    <input class="input-text" placeholder="Enter Last Name" type="text" formControlName="lastName"
                        required />
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <span class="input-label d-flex">Country</span>
            <div class="select-box-outer">
                <div class="select-box-div">
                    <img src="assets/icon-png/country-icon-1.png" class="icon-mat" />
                    <select (change)="bindCity($event.target.value)" required formControlName="country"
                        class="select-box">
                        <option value="" disabled>Select Company Country</option>
                        <option *ngFor="let country of countries" [value]="country">
                            {{country}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <span class="input-label d-flex">City</span>
            <div class="select-box-outer">
                <div class="select-box-div">
                    <img src="assets/icon-png/city-icon-1.png" class="icon-mat" />
                    <select required formControlName="city" class="select-box">
                        <option value="" disabled>Select Company City</option>
                        <option *ngFor="let city of cites" [value]="city">
                            {{city}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <span class="input-label d-flex">Email</span>
            <div class="select-box-outer">
                <div class="select-box-div">
                    <img src="assets/icon-png/email-icon.png" class="icon-mat" />
                    <input class="input-text" placeholder="Enter Email" type="text" formControlName="email" required />
                </div>
            </div>
        </div>
    </div>




    <div class="row">
        <div class="col-md-12">
            <span class="input-label d-flex">Contract Type</span>
            <div class="select-box-outer">
                <div style="background-color: #E9EBEF;" class="select-box-div">
                    <img src="assets/icon-png/contract-icon.png" class="icon-mat" />
                    <select style="background-color: #E9EBEF;" required formControlName="userType" class="select-box">
                        <option value="" disabled>Select Role</option>
                        <option value="Individual">
                            Individual
                        </option>
                        <option value="Corporate">
                            Corporate
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="row col-md-12 display-block">
        <div class="float-right">
            <button mat-dialog-close mat-stroked-button class="btn-right mr-3" (click)="backToList()" color="primary">Cancel</button>
            <button mat-dialog-close mat-raised-button class="btn-right" color="primary" [disabled]="!userForm.valid"
                (click)="saveUser()">
                Save</button>
        </div>
    </div>

</div>