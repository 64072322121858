import { Injectable } from '@angular/core';
import { ApiGatewayService, AuthService, IFndyObject } from '@thinglogix/ngx-foundry-core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProjectModel } from './Models/project-model';
import * as JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as';
import { DlServiceService } from '../dl-service.service';
import { Router } from '@angular/router';
import { v1 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  public createProject: ProjectModel;
  projectData: IFndyObject[];
  _projectData$ = new BehaviorSubject<boolean>(null);

  projectDevice: IFndyObject;
  _projectDevice$ = new BehaviorSubject<any>(null);
  _projectIssueDevice$ = new BehaviorSubject<any>(null);


  priceData: any;
  constructor(
    private apiGateway: ApiGatewayService,
    private authService: AuthService,
    private dlServiceService: DlServiceService,
    public router: Router,
  ) { }


  public getPriceData(): any {
    return this.priceData;
  }

  public setPriceData(_priceData: any): void {
    this.priceData = _priceData;
  }

  public getProjects(accountId: string): Observable<any> {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo || (userInfo && !userInfo['a.internal_user_role'])) {
      localStorage.removeItem('userInfo')
      this.authService.signOutUser();
      this.router.navigate(['/auth/login']);
      // To clear all instance of class
      location.reload();
    }
    const param = {
      accountId: accountId,
      userId: this.authService.user.id,
      internalRole: userInfo['a.internal_user_role'] ? userInfo['a.internal_user_role'] : ["Individual"]
    };
    return this.apiGateway.getApiClient(environment.customApiEndpoint).pipe(
      mergeMap((apiClient) => {
        return from(apiClient.Post('/project', {}, param));
      }),
      map((result: any) => result.data),
      first()
    );
  }

  public deleteTheFile(fileType, filesList, deleteFileUrl, projectId) {
    let newList = [];
    // for (let data of filesList) {
    //   if (!(data.url === deleteFileUrl)) {
    //     newList.push(data);
    //   }
    // }

    newList = filesList.filter((f: any) => { return (typeof f === 'object' && f.url && f.url !== deleteFileUrl) })  /*  typeof f === 'object' && f.url && */
    console.log(newList);
    let deviceData: any;

    if (fileType == 'Translator') {
      deviceData = {
        'a.translator_files': newList
      };
    } else if (fileType == 'Uploaded') {
      deviceData = {
        'a.source_files': newList
      };
    }

    return [projectId, deviceData];
  }


  public getProjectsData(): IFndyObject[] {
    return this.projectData;
  }

  public setProjectsData(projectData: IFndyObject[]) {
    this.projectData = projectData;
  }

  public updateProjectInList(projectData: any) {
    if (this.projectData && this.projectData.length > 0) {
      const selectProjects = this.projectData.filter((m: any) => m.deviceId == projectData.deviceId);
      this.projectData = this.projectData.filter((m: any) => m.deviceId !== projectData.deviceId);
      if (selectProjects && selectProjects.length > 0) {
        projectData['workHistoryInfo'] = selectProjects[0]['workHistoryInfo'];
        projectData['accountName'] = selectProjects[0]['accountName'];
      }
      this.projectData.push(projectData);
      this._projectData$.next(true);
    }
  }

  public updateProjectAndWorkHistoryInList(projectData: any, workHistoryData: any) {
    if (this.projectData && this.projectData.length > 0) {
      const selectProjects = this.projectData.filter((m: any) => m.deviceId == projectData.deviceId);
      this.projectData = this.projectData.filter((m: any) => m.deviceId !== projectData.deviceId);
      if (selectProjects && selectProjects.length > 0) {
        projectData['accountName'] = selectProjects[0]['accountName'];
  
        let userWorkHistoryList = selectProjects[0]['workHistoryInfo'].filter((m: any) => m.deviceId != workHistoryData['deviceId']);
        userWorkHistoryList.push(workHistoryData);
        projectData['workHistoryInfo'] = userWorkHistoryList;
        this.projectData.push(projectData);
        this._projectData$.next(true);
      }
    }
  }

  public addProjectInList(projectData: IFndyObject) {
    if (!this.projectData) {
      this.projectData = [];
    }
    this.projectData.push(projectData);
    this._projectData$.next(true);
  }

  refreshProjectList(project: any): void {
    if (this.projectData) {
      let projectList = this.projectData;
      let posIndex = 0;
      projectList.forEach(objProject => {
        if (objProject.deviceId === project.deviceId) {
          this.projectData[posIndex] = project;
        }
        posIndex++;
      });
      this._projectData$.next(true);
    }
  }

  removeProjectFromProjectList(project: any): void {
    if (this.projectData) {
      this.projectData = this.projectData.filter((m: any) => m.deviceId !== project.deviceId);
      this._projectData$.next(true);
    }
  }

  public createNgPaymentSession(projName, amount, projectId): Observable<any> {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const param = {
      projectId: projectId,
      productName: projName,
      unitAmount: amount,
      emailAddress: this.authService.user.email,
      accountId: this.authService.user.account_id,
      userId: this.authService.user.id,
      fName: userInfo['a.first_name'],
      lName: userInfo['a.last_name'],
      country_code: userInfo['a.country_code'],
      city: userInfo['a.city']
    };

    return this.apiGateway.getApiClient(environment.customApiEndpoint).pipe(
      mergeMap((apiClient) => {
        return from(apiClient.Post('/ngenius-payment', {}, param));
      }),
      map((result: any) => result.data),
      first()
    );
  }

  public updateNgPayment(reference): Observable<any> {
    const param = {
      reference: reference
    };
    return this.apiGateway.getApiClient(environment.customApiEndpoint).pipe(
      mergeMap((apiClient) => {
        return from(apiClient.Put('/ngenius-payment', {}, param));;
      }),
      map((result: any) => result.data),
      first()
    );
  }

  public getCreateProjectInfo() {
    return this.createProject
  }
  public getProjectBlank(): ProjectModel {
    return {
      companyId: null,
      companyName: null,
      projectId: '',
      projectName: '',
      sourceLanguage: '',
      targetLanguage: '',
      projectType: '',
      typeDoc: '',
      sourceFile: '',
      description: '',
      sourceWordCount: 0,
      sourcePageCount: 0,
      currency: '',
      paymentStatus: '',
      paymentTransectionId: '',
      status: '',
      notes: '',
      fileName: '',
      project_type: '',
      sourceFileFormat: '',
      targetFileFormat: '',
      invoiceRef: '',
      pricePerWord: 0,
      priceFileFormat: 0,
      priceDocType: 0,
      priceMinimum: 0,
      timePerWord: 0,
      timeMinimum: 0,
      userInfo: null
    }
  }

  public setCreateProjectInfo(createProject: ProjectModel) {
    this.createProject = createProject
  }

  public getCommentByProjectId(projectId: string, typeId: string, type: string): Observable<any> {
    const query = {
      userId: this.authService.user.id,
      typeId: typeId,
      type: type,
      projectId: projectId,
      userRoles: this.dlServiceService.userRoles.toString() == 'Corporate (Premium)' ? 'Corporate premium' : this.dlServiceService.userRoles.toString()
    };
    return this.apiGateway.getApiClient(environment.customApiEndpoint).pipe(
      mergeMap((apiClient) => {
        return from(apiClient.Get('/comments', query));
      }),
      map((result: any) => result.data),
      first()
    );
  }

  // public sendQuoteNotification(projectId: string, type: string,sendSupport?: boolean): Observable<any> {
  //   const param = {
  //     projectId: projectId,
  //     type:type
  //   };

  //   if(sendSupport) {
  //     param['suportTeamEmail'] = environment.supportEmail;
  //   }

  //   return this.apiGateway.getApiClient(environment.customApiEndpoint).pipe(
  //     mergeMap((apiClient) => {
  //       return from(apiClient.Post('/send-notification', {}, param));
  //     }),
  //     map((result: any) => result.data),
  //     first()
  //   );
  // }

  public createProjectHistory(project: any, workHistory: string, userId :string): void {
    const payload = {
      typeId: environment.projectWorkHistoryTypeId,
      isParent: false,
      created_by: this.authService.user.id,
      accountId: this.authService.user.account_id,
      'deviceId': v1(),
      'a.work_history': workHistory,
      'a.role': this.dlServiceService.getUserRole(),
      'a.project_id': project.deviceId,
      'a.name': project['a.name'],
      'a.file_name': project['a.source_files'],
      'a.status': project['a.status'],
      'a.created_user_id': userId
    };
    this.dlServiceService.createObject(payload).subscribe();
  }
  public sendNotification(projectId: string, type: string, sendSupport?: boolean): Observable<any> {
    const param = {
      projectId: projectId,
      type: type
    };
    if (sendSupport) {
      param['suportTeamEmail'] = environment.supportEmail;
    }
    return this.apiGateway.getApiClient(environment.customApiEndpoint).pipe(
      mergeMap((apiClient) => {
        return from(apiClient.Post('/send-notification', {}, param));
      }),
      map((result: any) => result.data),
      first()
    );
  }

  setProjectData(project: any): ProjectModel {
    let projectData: ProjectModel = this.getProjectBlank();
    //Step 1
    projectData.projectId = project.deviceId;
    projectData.projectName = project['a.name'];
    projectData.sourceLanguage = project['a.source_language'];
    projectData.targetLanguage = project['a.deliverable_language'];
    projectData.typeDoc = project['a.type_of_content'];
    projectData.sourceFileFormat = project['a.source_file_format'];
    projectData.targetFileFormat = project['a.target_file_format'];
    projectData.sourceWordCount = project['a.source_word_count'];
    projectData.sourcePageCount = project['a.source_page_count'];
    projectData.pricePerWord = project['a.price_per_word'];
    projectData.priceFileFormat = project['a.price_file_format'];
    projectData.priceDocType = project['a.price_doc_type'];

    //Step 2
    projectData.fileName = project['a.source_files'];

    //Step 3
    projectData.notes = project['a.notes'];
    projectData.description = project['a.description'];

    //step 4
    projectData.invoiceRef = project['a.invoice_ref'];
    return projectData;
  }

  public saveNotification(user_ref: string, notificationText: string, projectId: string): void {
    const payload = {
      typeId: environment.notificationTypeId,
      isParent: false,
      created_by: this.authService.user.id,
      accountId: this.authService.user.account_id,
      'deviceId': v1(),
      'a.project_id': projectId,
      'a.user_ref': user_ref,
      'a.notification_text': notificationText
    };
    this.dlServiceService.createObject(payload).subscribe();
  }
  public sendNotificationMail(projectId: string, email: string, name: string, userRef: string, type: string): Observable<any> {
    const param = {
      projectId: projectId,
      user_ref: userRef,
      type: type,
      customer_name: name,
      customer_email: email,
      worker_name: this.dlServiceService.getUserName() + '(' + this.dlServiceService.getUserRole() + ')'
    };
    return this.apiGateway.getApiClient(environment.customApiEndpoint).pipe(
      mergeMap((apiClient) => {
        return from(apiClient.Post('/send-notification', {}, param));
      }),
      map((result: any) => result.data),
      first()
    );
  }

  zipNdownloadFiles(files: any, filename: string): boolean {
    if (files && files.length > 0) {
      const urls = []
      for (let i = 0; i < files.length; i++) {
        urls.push(files[i].url)
      }
      const zip = new JSZip();
      let count = 0;

      const zipFilename = filename ? filename + '.zip' : "project-translate.zip";
      urls.forEach(async function (url) {
        const urlArr = url.split('/');
        const filename = urlArr[urlArr.length - 1];
        try {
          const file = await JSZipUtils.getBinaryContent(url)
          zip.file(filename, file, { binary: true });
          count++;
          if (count === urls.length) {
            zip.generateAsync({ type: 'blob' }).then(function (content) {
              saveAs(content, zipFilename);
              return true;
            });
          }
        } catch (err) {
          console.log(err);
          return false;
        }
      });
    } else {
      console.log('No files to download');
      return false;
    }
  }
}
