import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, UrlSegment} from '@angular/router';
import { AuthService } from "@thinglogix/ngx-foundry-core";
import { map, catchError } from "rxjs/operators";
import { Observable, of } from "rxjs";


@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate, CanLoad {
    constructor(private authService: AuthService,
                private router: Router) { }

    canActivate(_: any, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.checkLoggedIn(state.url);
    }

    canLoad(_: any, segments: UrlSegment[]): boolean | Observable<boolean> {
        const fullPath = segments.reduce((path, currentSegment) => {
            return `${path}/${currentSegment.path}`;
        }, '');
        return this.checkLoggedIn(fullPath);
    }

    private checkLoggedIn(returnUrl: string): boolean | Observable<boolean> {
        const { user } = this.authService;
        if (user && !user.api_user_only) {
            if (
                user.cognito &&
                user.cognito.api_gateway_endpoint &&
                user.cognito.cognito_identity_pool_id &&
                user.cognito.cognito_developer_provider_name
            ) {
                return this.authService.refreshAWSConfig().pipe(
                    map((valid) => {
                        if (!valid) {
                            this.signOut(returnUrl);
                        }
                        return valid;
                    }),
                    catchError((err) => {
                        console.error(err);
                        this.signOut(returnUrl);

                        return of(false);
                    })
                );
            }
            this.signOut(returnUrl);

            return of(false);
        }
        this.signOut(returnUrl);
        return of(false);
    }

    private signOut(returnUrl: string): void {
        this.authService.signOutUser();
        localStorage.clear();
        this.router.navigate(['/auth/login'], {
            queryParams: {
                returnUrl: returnUrl !== '' ? returnUrl : undefined,
            },
        });
    }
}