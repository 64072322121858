import { Component } from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {AuthService} from "@thinglogix/ngx-foundry-core";
import {Observable, Subject, Subscription} from "rxjs";
import {finalize, takeWhile} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {LoaderService} from "../../loader/loader.service";

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
    ngUnsubscribe = false;
    errorMessage: string = '';
    isSent: boolean = false;

    email: FormControl = new FormControl('', [Validators.required, Validators.email]);

    constructor(
        private authService: AuthService,
        private readonly loaderService: LoaderService
    ) {}

    sendEmail():void {
        const loaderSubscription: Subscription = this.loaderService.spinner$.subscribe();
        this.authService
            .forgotPassword(
                this.email.value,
                "",
                environment.thinglogixRootAccountId
            )
            .pipe(
                takeWhile(()=>!this.ngUnsubscribe),
                finalize(() => loaderSubscription.unsubscribe())
            )
            .subscribe(
                () => {
                    this.isSent = true;
                    // this.email.reset();
                },
                err => {
                    if (err.error) {
                        this.errorMessage = err.error.error;
                    } else {
                        this.errorMessage = err.message;
                    }
                }
            );
    }
}
