import { Component } from '@angular/core';
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "@thinglogix/ngx-foundry-core";
import { NotifierService } from "angular-notifier";
import { forkJoin, Subject, Subscription } from "rxjs";
import { finalize, takeWhile } from "rxjs/operators";
import { DlServiceService } from 'src/app/dl-service.service';
import { environment } from "../../../environments/environment";
import { LoaderService } from "../../loader/loader.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent {
    private readonly notifier: NotifierService;
    private ngUnsubscribe = false;

    constructor(
        private router: Router,
        private authService: AuthService,
        private notifierService: NotifierService,
        private readonly loaderService: LoaderService,
        private dlServiceService: DlServiceService
    ) {
        this.notifier = notifierService;
    }

    email: FormControl = new FormControl('', [Validators.required, Validators.email]);
    password: FormControl = new FormControl('', [Validators.required]);

    loginClick(): void {
        const loaderSubscription: Subscription = this.loaderService.spinner$.subscribe();
        this.authService
            .authorize(environment.thinglogixRootAccountId, this.email.value, this.password.value)
            .pipe(
                takeWhile(() => !this.ngUnsubscribe)
                // finalize(() => loaderSubscription.unsubscribe())
            )
            .subscribe(
                (res) => {
                    forkJoin([
                        this.dlServiceService.getObject(res.id),
                        this.dlServiceService.getObject(res.account_id)
                    ])
                        .subscribe(
                            (data: any) => {
                                localStorage.setItem("userInfo", JSON.stringify(data[0]));
                                localStorage.setItem("accountInfo", JSON.stringify(data[1]));
                                loaderSubscription.unsubscribe();
                                this.dlServiceService.setLoginUserRole();
                                this.router.navigate(['/']);
                            });
                },
                (err) => {
                    loaderSubscription.unsubscribe();
                    console.log(err);
                    this.notifier.notify('error', err.response?.data?.error || err.error?.error || err.error || err);
                }
            )
    }
}
