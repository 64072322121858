// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  fndyThingLogixApiGatewayEndpoint:
    "https://ik5ztaye9i.execute-api.us-west-2.amazonaws.com/v2prod",
  fndySecureApiGatewayKey: "0T5f607Onk4vnSIDi4gFh152OZMczcffa9afIArL",
  fndyCognitoDeveloperProviderName: "com.company-name.foundry",
  fndyCognitoIdentityPool: "us-west-2:fe5b30e7-7c3b-4e24-ac3a-0d4e807876ab",
  thinglogixRootAccountId: "a1572090-dd86-11eb-a96a-c96b9dbd8618",
  customerAccountId: "ab8777e0-0f13-11ec-834e-ed8941649036",
  customerAccountName: "Customer",
  backOfficeAccountId: "a2eceb70-0f0d-11ec-a655-355c3f6d5aa2",
  hosts: [
    "http://localhost:4200",
    "http://localhost:4300",
    "https://foundry-dev.thinglogix.com",
    "https://foundry-beta.thinglogix.com",
    "https://foundry.thinglogix.com",
  ],
  customApiEndpoint:
    "https://mxujzqyxe4.execute-api.us-west-2.amazonaws.com/v1",
  projectTypeId: "e1b38cd0-def0-11eb-8007-d916885d80a6",
  userInfoTypeId: "756dc410-deef-11eb-b5c6-41160923efe7",
  commentTypeId: "83250c60-def1-11eb-8007-d916885d80a6",
  folderTypeId: "f9ca88e0-0ca5-11ec-8160-c3d86d533847",
  folderFileTypeId: "48642480-0efd-11ec-804a-310e0461e7e0",
  accountInfoTypeId: "21e0f3c0-def0-11eb-b5c6-41160923efe7",
  engineRateId: "f2358b20-e891-11ec-b33e-03c8bc9a25ea",
  JobCountTypeID: "1351c220-9481-11ec-8990-8fe9a6c70a39",
  notificationTypeId: "58c92dc0-f5e8-11ec-9f2e-8b9333dd7622",
  projectWorkHistoryTypeId: "50db06c0-0992-11ed-a4c2-51d9f8432cc0",
  supportEmail: "giriraj@thinglogix.com",
  // rateEngineTypeId : "",
  //unuse
  // fileFormatPriceTypeId: "bdbb2120-5da4-11ec-809c-25bcc3f9dd77",
  // documentTypeId: "1b74fbd0-92ff-11ec-bad8-dfd4cf43f4bf",
  // 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
