import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
  public title: string;
  public description: string;
  public routeRedirect:string;
  constructor(@Inject(MAT_DIALOG_DATA) public resultData: any,) { }

  ngOnInit(): void {
    if(this.resultData){
      this.title = this.resultData.title;
      this.description = this.resultData.description;
      this.routeRedirect = this.resultData.redirect
    }
  }
}
