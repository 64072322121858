import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectModel } from 'src/app/project/Models/project-model';
import { ProjectService } from 'src/app/project/project.service';
import { ProjectAdditionInfoComponent } from '../project-addition-info/project-addition-info.component';
import { ProjectInfoComponent } from '../project-info/project-info.component';

@Component({
  selector: 'app-project-file-upload',
  templateUrl: './project-file-upload.component.html',
  styleUrls: ['./project-file-upload.component.scss']
})
export class ProjectFileUploadComponent implements OnInit {
  public projectData: ProjectModel;
  public sourceFileData: any;

  constructor(private dialog: MatDialog,
    private projectService: ProjectService,
    private dialogRef: MatDialogRef<ProjectFileUploadComponent>) { }

  ngOnInit(): void {
    this.projectData = this.projectService.getCreateProjectInfo();
    if (this.projectData.fileName) {
      this.sourceFileData = this.projectData.fileName;
    }
  }

  filesUpload(fileData: any) {
    this.projectData.fileName = fileData;
    this.sourceFileData = this.projectData.fileName;
    this.projectService.setCreateProjectInfo(this.projectData);
  }

  nextStep() {
    this.projectService.setCreateProjectInfo(this.projectData);
    this.dialog.open(ProjectAdditionInfoComponent);
  }

  callBack() {
      this.dialog.open(ProjectInfoComponent);
      this.dialogRef.close();
  }
}
