import { NgModule } from '@angular/core';
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatOptionModule} from "@angular/material/core";
import {MatDividerModule} from "@angular/material/divider";
import {MatCardModule} from "@angular/material/card";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {CommonModule} from "@angular/common";
import {MainComponent} from "./main.component";
import {RouterModule} from "@angular/router";

@NgModule({
    declarations: [
        MainComponent,
    ],
    imports: [
        MatSidenavModule,
        MatToolbarModule,
        MatIconModule,
        MatFormFieldModule,
        MatOptionModule,
        MatDividerModule,
        MatCardModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatInputModule,
        CommonModule,
        RouterModule,
    ],
    exports: [],
    providers: []
})
export class MainModule {}
