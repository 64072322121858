<mat-card class="shadow" style="padding: 15px 31px;margin-top:151px ;">
    <mat-card-header style="margin-bottom: 21px;">
        <mat-card-title style="margin-top: 16px;margin-bottom: 21px;font-size: 36px;">Reset Password</mat-card-title>
        <mat-card-subtitle style="color: #7C828D;">ENTER YOUR NEW PASSWORD</mat-card-subtitle>
        <mat-card-subtitle style="margin-bottom: 31px;margin-top: 41px;">
            <img class="cls-img" src="assets/icon-png/logo-big.png" />
        </mat-card-subtitle>
    </mat-card-header>

    <form (ngSubmit)="resetPassword()" #resetForm="ngForm" >
        <mat-card-content>
            <span class="font-weight-bold">New Password</span>
            <mat-form-field appearance="outline" class="w-100">
                <mat-icon style="background-image: url('assets/icon-png/password-icon.png');height: 17px;" matPrefix *ngIf="!resetModel.password"></mat-icon>
                <input matInput placeholder="Enter password" 
                type="password"  autocomplete="off"  name="password"   id="password" 
                 [pattern]="passwordRegex"
                 [(ngModel)]="resetModel.password"
                #password="ngModel"
                required>
            </mat-form-field>
            <small *ngIf="password.errors?.pattern" class="text-danger">
                Password should be with {{ passwordPolicy }}
            </small>

            <span class="font-weight-bold">Confirm New Password</span>
            <mat-form-field appearance="outline" class="w-100">
                <mat-icon style="background-image: url('assets/icon-png/password-icon.png');height: 17px;" matPrefix *ngIf="!resetModel.confirmPassword"></mat-icon>
                <input matInput placeholder="Confirm Password" 
                type="password"  autocomplete="off"  name="confirmPassword"  [(ngModel)]="resetModel.confirmPassword"
                 required>
            </mat-form-field>
            <small *ngIf="resetModel.password !== resetModel.confirmPassword" class="text-danger">
                Password does not match the confirm password.
            </small>
        </mat-card-content>
        <button  type="submit" [disabled]="!resetForm.form.valid || resetModel.password !== resetModel.confirmPassword"
         mat-raised-button color="primary" 
        class="w-100">Confirm New Password</button>

        <div *ngIf="isSent" class="mat-card-content success-msg">
            <p>
                Password changed successfully.
                <a routerLink="/auth/login" href="javascript:void(0)">Click Here</a> to login
            </p>
        </div>
        <div *ngIf="!linkIsValid" class="mat-card-content success-msg">
            <p>
              {{errorMessage}}
            </p>
        </div>

    </form>
</mat-card>
