import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivateUserComponent } from './activate-user/activate-user.component';
import { AuthComponent } from './auth.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AlreadyAuthenticatedGuard } from './login/already-authenticated-guard.service';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignupComponent } from "./signup/signup.component";

const routes: Routes = [
    {
        path: 'auth',
        canActivate: [AlreadyAuthenticatedGuard],
        component: AuthComponent,
        children: [
            {
                path: 'login',
                component: LoginComponent
            },
            {
                path: 'signup',
                component: SignupComponent
            },
            {
                path: 'forgotPassword',
                component: ForgotPasswordComponent
            },
            {
                path: 'resetPassword',
                component: ResetPasswordComponent
            },
            {
                path: 'activateUser',
                component: ActivateUserComponent
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'login'
            },
            {
                path: '**',
                redirectTo: 'login'
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class AuthRoutingModule {}
