<mat-card *ngIf="!isSent" class="shadow" style="padding: 15px 31px;margin-top:151px ;">
    <mat-card-header style="margin-bottom: 21px;">
        <mat-card-title style="margin-top: 16px;margin-bottom: 21px;font-size: 36px;">Forgot Password</mat-card-title>
        <mat-card-subtitle style="color: #7C828D;">ENTER YOUR EMAIL ADDRESS TO RESET PASSWORD</mat-card-subtitle>
        <mat-card-subtitle style="margin-bottom: 31px;margin-top: 35px;">
            <img class="cls-img" src="assets/icon-png/logo-big.png" />
        </mat-card-subtitle>
    </mat-card-header>

    <form (ngSubmit)="sendEmail()">
        <mat-card-content>
            <span class="font-weight-bold">Email</span>
            <mat-form-field appearance="outline" class="w-100">
                <mat-icon  style="background-image: url('assets/icon-png/email-icon.png');height: 13px;" matPrefix *ngIf="!email.value"></mat-icon>
                <input matInput placeholder="Enter your Email" [formControl]="email" required>
            </mat-form-field>
        </mat-card-content>
        <button type="submit" style="height:50px ;margin-bottom: 21px;" mat-raised-button color="primary"  class="w-100">Reset Password</button>
    </form>
</mat-card>

<mat-card *ngIf="isSent" style="margin-top: 151px;" class="shadow d-flex justify-content-center flex-column">
    <mat-card-header>
        <mat-card-title  >Check your Email</mat-card-title>
        <mat-card-subtitle style="width: 291px;
        text-align: left;color: #707070;text-transform: capitalize;" class="pt-2">
            We've sent an email to <span style="color:#2C6231 ;font-weight:400 ;"></span> {{email.value}} Click the link in the email to reset your password.

          
          
        </mat-card-subtitle>
        <mat-card-subtitle style="margin-bottom: 31px;margin-top: 41px;">
            <img class="cls-img" src="assets/icon-png/logo-big.png" />
        </mat-card-subtitle>
    </mat-card-header>

    <form (ngSubmit)="isSent=false">
        <mat-card-content class="d-flex flex-column justify-content-center align-items-center">
            <img class="email" src="assets/imgs/email.svg" />
            <span class="remark">If you didn’t receive anything, click the button below</span>
        </mat-card-content>
        <button type="submit" style="height:50px ;margin-bottom: 21px;" mat-raised-button color="primary" class="w-100">Resend Verification Email</button>
    </form>
</mat-card>