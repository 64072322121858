import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {ReplaySubject} from 'rxjs';
import {AuthService} from "@thinglogix/ngx-foundry-core";
import {takeUntil} from "rxjs/operators";
import {NotifierService} from "angular-notifier";

@Component({
    selector: 'app-activate-user',
    templateUrl: './activate-user.component.html',
    styleUrls: ['./activate-user.component.scss']
})
export class ActivateUserComponent implements OnInit, OnDestroy {
    user_id: any;
    account_id: any;
    loginDisabled = false;

    private ngUnsubscribe: ReplaySubject<any> = new ReplaySubject<any>(1);

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private notifier: NotifierService,
    ) {}

    ngOnInit(): void {
        if (this.authService.user) {
            this.authService.signOutUser();
        }
        this.route.queryParams
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
            this.user_id = params['user_id'];
            this.account_id = params['account_id']; // Print the parameter to the console.
        });
        this.route.parent.data
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(({ data }) => {
                
            // const accountMetadata = data.accountMetadata;
            // if (accountMetadata) {
            //     this.loginDisabled = accountMetadata.loginDisabled;
            // }
        });

        this.authService
            .activateUser(this.account_id, this.user_id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                () => {
                    this.notifier.notify('success', 'User Activated Successfully')
                },
                err => {
                    this.notifier.notify("error", err.error?.error || err.message);
                }
            );
    }

    loginClick(): void {
        this.router.navigateByUrl('auth/login')
    }
    ngOnDestroy(): void {
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();
    }
}
