import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthApiServiceService {
  private httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      }),
  };

  constructor(private httpClient: HttpClient) {}

  signup(signupPayload): Observable<any> {
      return this.httpClient.post(
        environment.customApiEndpoint + '/signup',
          JSON.stringify(signupPayload),
          this.httpOptions
      );
  }
}
