import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFileModel, S3Service } from '@thinglogix/ngx-foundry-core';
import { Subject } from 'rxjs';
import {  takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dl-file-upload',
  templateUrl: './dl-file-upload.component.html',
  styleUrls: ['./dl-file-upload.component.scss']
})
export class DlFileUploadComponent implements OnInit {
  @Input() s3Prefix: string;
  @Output() actionEvent = new EventEmitter();
  @Input() uploadedFiles: any;

  fileIcount = 0;
  uploadingFile = false;
  showAnotherFile = false;
  ngUnsubscribe = new Subject();
  s3FileResponses: any = [];
  filesInfo: any[] = [];
  
  constructor(private s3Service: S3Service) { }

  
  ngOnInit(): void {
    if (this.uploadedFiles) {
      for (let index = 0; index < this.uploadedFiles.length; index++) {
        this.showAnotherFile = true;
        this.s3FileResponses.push(this.uploadedFiles[index]);
        this.filesInfo.push({
          s3Result: this.uploadedFiles[index].key,
          fileData: '',
          fileName: this.uploadedFiles[index].fileName,
          fileSize: this.uploadedFiles[index].fileSize,
          processMode: 'determinate',
          processColor: 'green',
        });
      }
    }
  }

  openFileBox() : void {
    document.getElementById('fileDropRef').click();
  }

  setS3Response(iCount: any, res: any): void {
    const index = this.fileIcount + iCount;
    this.filesInfo[index]['s3Result'] = res.key;
    this.filesInfo[index]['processMode'] = 'determinate';
    this.filesInfo[index]['processColor'] = 'green',

    res['fileName'] = this.filesInfo[index]['fileName'];
    res['fileSize'] = this.filesInfo[index]['fileSize'];
    this.s3FileResponses.push(res);
  }

  uploadFileInS3(file: File, files: FileList, iCount: number): void {
    const reader = new FileReader();
    const prefix = this.s3Prefix + '/';
    reader.onload = () => {

      const body: IFileModel = {
        data: btoa(reader.result as string),
        name: prefix + '_' + file.name,
        size: file.size,
        type: file.type,
      };
      this.uploadingFile = true;
      this.s3Service
        .uploadFile(body)
        .pipe(
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(
          (res) => {
            if (iCount === files.length - 1) {
              this.setS3Response(iCount, res);
              this.actionEvent.emit(this.s3FileResponses);
              this.uploadingFile = false;
              this.showAnotherFile = true;
            } else {
              this.setS3Response(iCount, res);
              iCount++;
              this.uploadFileInS3(
                files[iCount],
                files,
                iCount
              );
            }
          },
          (err) => {
            console.error(err);
          }
        );
    };

    reader.readAsBinaryString(file);
  }


  onFileDropped($event) {
    this.prepareFilesList($event);
  }
  fileBrowseHandler(files: FileList) {
    this.prepareFilesList(files);
  }
  // deleteFile(index: number) {
  //   const s3Key = this.filesInfo[index]['s3Result'];
  //   this.s3FileResponses = this.s3FileResponses.filter(m => m.key !== s3Key);
  //   this.filesInfo.splice(index, 1);
  //   this.actionEvent.emit(this.s3FileResponses);
  // }
  prepareFilesList(files: FileList) {
    this.fileIcount = this.filesInfo.length;
    for (let index = 0; index < files.length; index++) {
      this.filesInfo.push({
        s3Result: '',
        fileName: files[index]['name'],
        fileSize: files[index]['size'],
        fileData: files[index],
        processMode: 'determinate',
        processColor: 'orange',
      });
    }
    const file = files[0];
    if (file) {
      this.uploadFileInS3(file, files, 0);
    }
  }
  // formatBytes(bytes, decimals) {
  //   if (bytes === 0) {
  //     return '0 Bytes';
  //   }
  //   const k = 1024;
  //   const dm = decimals <= 0 ? 0 : decimals || 2;
  //   const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  //   const i = Math.floor(Math.log(bytes) / Math.log(k));
  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  // }
}
