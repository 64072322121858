import { Component, OnInit } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
    templateUrl: 'auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
    logo = 'assets/imgs/logo.png';
    public btnType: string;

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.btnType = this.router.url.split('/').pop();
        this.router.events.subscribe( (e) => {
            if (e instanceof NavigationEnd) {
                console.log(e);
                this.btnType = e.url.split('/').pop();
            }
        })
    }
}
