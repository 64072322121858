import { NgModule } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { AuthService } from "@thinglogix/ngx-foundry-core";
import { MainComponent } from "../main/main.component";
import { AuthGuard } from "../core/auth-guard.service";

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: '/project/list/new',
                pathMatch: 'full'
                // component: MainComponent
            },
            {
                path: '',
                loadChildren: () => import('../project/project.module').then((m) => m.ProjectModule),
            },
            {
                path: '',
                loadChildren: () => import('../user/user.module').then((m) => m.UserModule),
            },
            {
                path: '',
                loadChildren: () => import('../employee/employee.module').then((m) => m.EmployeeModule),
            },
            {
                path: '',
                loadChildren: () => import('../folder/folder.module').then((m) => m.FolderModule),
            },
            {
                path: '',
                loadChildren: () => import('../account/account.module').then((m) => m.AccountModule),
            },
            {
                path: '',
                loadChildren: () => import('../report/report.module').then((m) => m.ReportModule),
            },
            {
                path: '',
                loadChildren: () => import('../rate-engine/rate-engine.module').then((m) => m.RateEngineModule),
            },
            
            {
                path: '**',
                redirectTo: '/profile/edit'
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class HomeRoutingModule {
    constructor(private router: Router, private authService: AuthService) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // this.authService.previousUrl = event.url;
            }
        });
    }
}
