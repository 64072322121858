<h2 mat-dialog-title>Specialized Translation Project
    <img src="/assets/icon-png/cross-icon-1.png" mat-dialog-close style="width: 11px;float: right;"  >
</h2>
<ng-container >
    <div class="d-flex w-100">
        <span class="dialog-subtitle">02. Project File </span>
    </div>

    <div class="w-100">
        <span class="input-label">Upload the file</span>
    </div>

    <div class="w-100">
        <span class="input-label" style="color: #ED973B;">
            Supported Extension: PDF, JPG, PNG, doc...
        </span>
    </div>

    <div class="m-4">
        <app-dl-file-upload [s3Prefix]="'sourceFiles'" [uploadedFiles]="sourceFileData"
            (actionEvent)="filesUpload($event)">
        </app-dl-file-upload>

    </div>

</ng-container>

<mat-dialog-actions style="display:block ;" align="end">
    <button mat-stroked-button mat-button color="primary" (click)="callBack()">Back</button>
    <button mat-raised-button color="primary" mat-dialog-close cdkFocusInitial [disabled]="!sourceFileData"
        (click)="nextStep()">Next</button>
</mat-dialog-actions>