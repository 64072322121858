import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest } from 'rxjs';
import { DlServiceService } from 'src/app/dl-service.service';
import { LoaderService } from 'src/app/loader/loader.service';
import { ProjectModel } from 'src/app/project/Models/project-model';
import { ProjectService } from 'src/app/project/project.service';
import { MessageBoxComponent } from 'src/app/shared/message-box/message-box.component';
import { environment } from 'src/environments/environment';
import { ProjectInfoComponent } from '../project-info/project-info.component';
import { SignupUserComponent } from '../signup-user/signup-user.component';


@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss']
})
export class SelectCompanyComponent implements OnInit {
  public projectData: ProjectModel;
  public flagShow = false;
  rows: any[] = [];
  userList: any[] = [];
  userInfo: any;
  filterData: any[] = [];
  constructor(private dialog: MatDialog,
    private projectService: ProjectService,
    private dlService: DlServiceService,
    private readonly loaderService: LoaderService,) {
  }

  ngOnInit(): void {
   this.loadAccount();
    this.projectData = this.projectService.getCreateProjectInfo();
    if (this.projectData && this.projectData.companyId)
      this.companyForm.patchValue({
        companyId: this.projectData.companyId,
        companyName: this.projectData.companyName
      });
  }
  
  public loadAccount(): void {
    this.companyForm.controls['companyName'].valueChanges.subscribe(value => {
      if(value && value.length > 0){
        this.searchCompany(value);
      }
    });
    const loaderSubscription = this.loaderService.spinner$.subscribe();
    combineLatest([
      this.dlService.getObjectsByType(environment.accountInfoTypeId),
      this.dlService.getObjectsByType(environment.userInfoTypeId)
    ]).subscribe((data: any) => {
      this.rows = [];
      data[0].forEach(company => {
        this.userList = data[1];
        company['userCount'] =  this.userList.filter((m: any) => m['a.account_ref'] === company.deviceId).length;
        this.rows.push(company);
      });
      this.rows = this.rows.filter((m: any) => m['a.configureaccount'] !== 'True' &&  m['userCount']  > 0);
      this.filterList();
      loaderSubscription.unsubscribe();
    });

  }

  public selectCompany(accountId: string): void {
    let firstUserInfo = this.userList.filter((m: any) => m['a.account_ref'] === accountId);
    if (firstUserInfo && firstUserInfo.length > 0) {
      firstUserInfo = firstUserInfo.sort((a: any, b: any) => {
        a = a['Created'] || 0;
        b = b['Created'] || 0;
        return b - a;
      });
      //first created user in the company
      this.userInfo = firstUserInfo[0];

      const company = this.rows.filter((m: any) => m.deviceId === accountId);
      this.companyForm.patchValue({
        companyId: company[0]['deviceId'],
        companyName: company[0]['name']
      });
      this.flagShow = false;
    }
  }

  public filterList(): void {
    this.filterData = this.rows;
    this.filterData = this.filterData.map(data => ({
      deviceId: data.deviceId,
      'name': data['name']
    }));

    this.filterData = this.filterData.sort((a: any, b: any) => {
      a = a['name'] || "";
      b = b['name'] || "";
      return a.localeCompare(b, "en", { numeric: true });
    });

    // this.filterData.sort((a, b) => Number(b.created) - Number(a.created));
  }

  public searchCompany(cmpVal: string): void {
    this.flagShow = true;
    this.filterList();
    this.filterData = this.filterData.filter((m: any) => m['name'].toLowerCase().includes(cmpVal.toLowerCase()));
  }

  public companyForm = new FormGroup({
    companyId: new FormControl('', [Validators.required]),
    companyName: new FormControl('', [Validators.required])
  });

  showCompanies(): void {
    this.flagShow = true;
  }

  createUserOpen(): void {
    this.dialog.open(SignupUserComponent, {
      width: '551px',
      disableClose: true
    });
  }

  gotoNext(): void {
    const formData = this.companyForm.value;
    let projData = this.projectService.getProjectBlank();
    // this.dlService.setSelectedAccountId(formData.companyId);
    projData.companyId = formData.companyId;
    projData.companyName = formData.companyName;
    projData.userInfo = this.userInfo;
    this.projectService.setCreateProjectInfo(projData);
    this.dialog.open(ProjectInfoComponent, {
      width: '910px',
      disableClose: true
    });
  }

}
