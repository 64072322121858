import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MD5 } from 'crypto-js';
import { AuthService } from '@thinglogix/ngx-foundry-core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ResetPasswordModel } from './reset-password-model';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
    errorMessage: string;
    isSent = false;
    resetModel = new ResetPasswordModel();
    resetPasswordInProgress = false;
    linkIsValid = true;
    account: string;
    userId: string;
    token: string;
    passwordPolicies: any[];
    loginDisabled = false;
    disableAll = false;
    passwordRegex = /./;
    passwordPolicy = '';

    private ngUnsubscribe = new Subject();

    constructor(
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        const resetPasswordRedirectTimeout = 3000;
        if (this.authService.user) {
            this.authService.signOutUser();
        }

        this.route.queryParams.subscribe((queryParams) => {
            this.userId = queryParams.user_id;
            this.token = queryParams.token;
            this.account = queryParams.account_id;
        });

        this.passwordPolicies = this.authService.passwordPolicies;
        if (!this.userId || !this.token) {
            this.linkIsValid = false;
            return;
        }

        this.authService
            .checkResetPassword(this.account, this.userId, this.token)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (data) => {
                    // eslint-disable-next-line
                    for (const j in this.passwordPolicies) {
                        if (this.passwordPolicies[j].regex === data.passwordPolicy) {
                            this.passwordPolicy = this.passwordPolicies[j].name.toLowerCase();
                            this.passwordRegex = this.passwordPolicies[j].pattern;
                            break;
                        }
                    }
                    console.log('policy', this.passwordPolicy,this.passwordRegex);
                },
                (err) => {
                    console.error('error', err);
                    this.linkIsValid = false;
                    this.errorMessage = err.error.error;
                    this.disableAll = true;
                    if (!this.loginDisabled) {
                        setTimeout(() => this.backToLogin(), resetPasswordRedirectTimeout);
                    }
                }
            );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

     backToLogin(): void {
        this.router
            .navigate(['/auth/login'], {
                queryParams: { account: this.account },
            })
            .catch(() => 'obligatory catch');
    }

    resetPassword(): void {
        this.errorMessage = null;
        const resetPasswordRedirectTimeout = 3000;
        this.resetModel.password = this.resetModel.password.trim();
        this.resetModel.confirmPassword = this.resetModel.confirmPassword.trim();
        if (
            this.resetModel.password === '' ||
            this.resetModel.confirmPassword === '' ||
            this.resetModel.password !== this.resetModel.confirmPassword
        ) {
            return;
        }

        this.resetPasswordInProgress = true;

        this.authService
            .setNewPassword(this.account, this.userId, this.token, MD5(this.resetModel.password).toString())
            .pipe(
                finalize(() => {
                    this.resetPasswordInProgress = false;
                    this.disableAll = true;
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(
                () => {
                    this.isSent = true;
                },
                (err) => {
                    console.error(err);
                    if (err.error) {
                        this.errorMessage = err.error.error;
                    } else if (err.message.includes('Authentication error. User can not access Foundry UI.')) {
                        if (!this.loginDisabled) {
                            setTimeout(() => this.backToLogin(), resetPasswordRedirectTimeout);
                        }
                    } else {
                        this.errorMessage = err.message;
                    }
                }
            );
    }
}
