<h2 mat-dialog-title>Specialized Translation Project
    <img src="/assets/icon-png/cross-icon-1.png" mat-dialog-close style="width: 11px;float: right;">
</h2>

<ng-container [formGroup]="companyForm">
    <div class="w-100" style="margin-bottom:15px ;">
        <span class="dialog-subtitle">01. Customer Information</span>
    </div>

    <div class="w-100">
        <span class="input-label d-flex">Select Company/User Name</span>
        <div class="select-box-outer">
            <div class="select-box-div">
                <img  class="img-search" src="assets/icon-png/search-grey-icon.png" alt="">
                <input   maxlength="40" class="input-box" placeholder="Select Company/User Name"
                    formControlName="companyName" required>
                <img *ngIf="!flagShow" class="img-arrow-down" (click)="showCompanies()"
                    src="assets/icon-png/down-arrow-grey.png">
                <img *ngIf="flagShow" class="img-arrow-down" (click)="flagShow = false"
                    src="assets/icon-png/up-arrow-box.png">
                <div *ngIf="flagShow" class="div-company">
                    <span *ngFor = "let item of filterData" (click) ="selectCompany(item.deviceId)" class="spn-company">{{item.name}}</span>
                </div>
                <div *ngIf="flagShow" class="div-new-company">
                    <span class="spn-acr-company">Looking for Someone Else ?</span>
                    <a class="acr-company" (click)="createUserOpen()" href="javascript:void('0')">Add New
                        Company/User</a>
                </div>
            </div>

        </div>

    </div>


</ng-container>
<div class="w-100">
    <mat-dialog-actions align="end">
        <button mat-stroked-button mat-button mat-dialog-close color="primary">Cancel</button>
        <button mat-raised-button color="primary" mat-dialog-close cdkFocusInitial [disabled]="!companyForm.valid"
            (click)="gotoNext()">Next</button>
    </mat-dialog-actions>
</div>