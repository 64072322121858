import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectModel } from 'src/app/project/Models/project-model';
import { ProjectService } from 'src/app/project/project.service';
import { ProjectQuteComponent } from '../project-qute/project-qute.component';
import { ProjectInfoComponent } from '../project-info/project-info.component';
import { DlServiceService } from 'src/app/dl-service.service';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/loader/loader.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { v1 } from 'uuid';
import { AuthService } from '@thinglogix/ngx-foundry-core';
import { MessageBoxComponent } from 'src/app/shared/message-box/message-box.component';

@Component({
  selector: 'app-project-confirmation',
  templateUrl: './project-confirmation.component.html',
  styleUrls: ['./project-confirmation.component.scss']
})
export class ProjectConfirmationComponent implements OnInit {
  public projectData: ProjectModel;
  public invoiceRef = '';
  public projectSaved: any;
  public loaderSubscription: Subscription;
  public totalPrice: any = 0;
  public vat: any = 0;
  public totalPriceWithVat: any = 0;
  public translationTime: any = 0;

  constructor(private dialog: MatDialog,
    private projectService: ProjectService,
    public dlService: DlServiceService,
    private readonly loaderService: LoaderService,
    private authService: AuthService,) {
  }

  ngOnInit(): void {
    this.projectData = this.projectService.getCreateProjectInfo();
  }

  gotoNext() {
    if (this.dlService.showCustomer()) {
      this.dialog.open(ProjectQuteComponent, {
        width: '910px',
        disableClose: true
      });
    } else {
      if (!this.projectData.invoiceRef) {
        this.getInvoiceRef();
      } else {
        this.invoiceRef = this.projectData.invoiceRef;
        this.calculatePriceNtime();
      }
    }
  }


  getInvoiceRef(): void {
    this.loaderSubscription = this.loaderService.spinner$.subscribe();
    this.dlService.getObjectsByType(environment.JobCountTypeID).subscribe((data: any) => {
      let jobData = data;
      if (data && data.length > 0) {
        jobData = data.filter(m => m['a.job_date'] === moment().format('YYMMDD'));
      }
      // set invoice ref and update
      if (jobData && jobData.length > 0) {
        const count = parseInt(jobData[0]['a.count'], 10) + 1;
        const jobPayload = {
          'a.count': count,
        };
        this.projectData.invoiceRef = 'AMQ' + moment().format('YYMMDD') + count.toString();
        this.invoiceRef = this.projectData.invoiceRef;
        this.dlService.updateObject(jobData[0]['deviceId'], jobPayload).subscribe();
        this.loaderSubscription.unsubscribe();
        this.calculatePriceNtime();
      }
      else {
        this.projectData.invoiceRef = 'AMQ' + moment().format('YYMMDD') + '1';
        this.invoiceRef = this.projectData.invoiceRef;
        const jobPayload = {
          deviceId: v1(),
          typeId: environment.JobCountTypeID,
          accountId: this.authService.user.account_id,
          isParent: false,
          created_by: this.authService.user.id,
          'a.count': 1,
          'a.job_date': moment().format('YYMMDD')
        };
        this.dlService.createObject(jobPayload).subscribe();
        this.loaderSubscription.unsubscribe();
        this.calculatePriceNtime();
      }
    })
  }

  calculatePriceNtime(): void {
    // Price Calculation
    const translatePrice = parseFloat(this.projectData.pricePerWord.toString()) * parseFloat(this.projectData.sourceWordCount.toString());
    const pageFormatPrice = parseFloat(this.projectData.priceFileFormat.toString()) * parseFloat(this.projectData.sourcePageCount.toString());
    this.totalPrice = translatePrice + pageFormatPrice + parseFloat(this.projectData.priceDocType.toString());
    if (parseFloat(this.projectData.priceMinimum.toString()) > this.totalPrice) {
      this.totalPrice = this.projectData.priceMinimum;
    }

    this.vat = 5 * this.totalPrice / 100;
    this.totalPriceWithVat = this.totalPrice + this.vat;

    //Time Calculation
    this.translationTime = parseFloat(this.projectData.timePerWord.toString()) * parseFloat(this.projectData.sourceWordCount.toString()) / 3600;
    if (parseFloat(this.projectData.timeMinimum.toString()) > this.translationTime) {
      this.translationTime = this.projectData.timeMinimum;
    }

    this.saveProject();
  }

  saveProject(): void {
    this.loaderSubscription = this.loaderService.spinner$.subscribe();
    if (this.projectData) {
      const payload = {
        typeId: environment.projectTypeId,
        isParent: false,
        created_by: this.authService.user.id,
        accountId: this.authService.user.account_id,
        'a.name': this.projectData.projectName,
        'a.deliverable_language': this.projectData.targetLanguage,
        'a.source_language': this.projectData.sourceLanguage,
        'a.description': this.projectData.description,
        'a.source_files': this.projectData.fileName,
        'a.payment_status': 'unpaid',
        'a.status': "New",
        'a.source_word_count': this.projectData.sourceWordCount,
        'a.source_page_count': this.projectData.sourcePageCount,
        'a.notes': this.projectData.notes,
        
        'a.source_file_format': this.projectData.sourceFileFormat,
        'a.target_file_format': this.projectData.targetFileFormat,
        'a.invoice_ref': this.projectData.invoiceRef,
        'a.type_of_content': this.projectData.typeDoc,
        'a.translation_time': this.translationTime,
        'a.vat_cost': this.vat,
        'a.fee': this.totalPriceWithVat,
        'a.conversion_cost': this.totalPrice,
        'a.price_per_word': this.projectData.pricePerWord,
        'a.price_file_format': this.projectData.priceFileFormat,
        'a.price_doc_type': this.projectData.priceDocType,
        'shortId': 'a.project_count'
      };
      if (this.projectData.projectId) {
        this.dlService.updateObject(this.projectData.projectId, payload).subscribe((data) => {
          this.projectService.updateProjectInList(data);
          this.projectService._projectData$.next(true);
          this.projectSaved = data;
          this.showSuccessMessage('update');
          this.loaderSubscription.unsubscribe();
        });
      } else {
        payload['deviceId'] = v1();
        payload['a.user_ref'] = this.projectData.userInfo['deviceId'];
        payload['a.username'] = this.projectData.userInfo['name']? this.projectData.userInfo['name'] : this.projectData.userInfo['a.name'];
        payload['a.contract_account_ref'] = this.projectData.userInfo['a.account_ref'];
        payload['a.internal_status'] = 'New';
        this.dlService.createObject(payload).subscribe((data: any) => {
          this.projectService.createProjectHistory(data,'Upload by Customer Service',payload['a.user_ref']);
          this.projectSaved = data;
          this.projectService.sendNotification(this.projectSaved.deviceId, 'invoice', false).subscribe();
          this.projectService.addProjectInList(data);
          this.showSuccessMessage('create');
          this.loaderSubscription.unsubscribe();
        });
      }
    }
  }

  showSuccessMessage(mode: string): void {
    this.dialog.open(MessageBoxComponent, {
      width: '500px',
      data: {
        imgIcon: '/assets/icon-png/proj-success.png',
        header: mode === 'create' ? 'Project Submit Successfully.' : 'Project Updated Successfully.',
        csProj: mode === 'create' ? 'data' : null
      }
    });
  }

  callBack() {
    this.dialog.open(ProjectInfoComponent, {
      width: '910px',
      disableClose: true
    });
  }
}
