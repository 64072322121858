<ng-container>
    <div class="invoice-bg-img1">
        <div class="row1">
            <div class="font-invoice">
                Invoice <span class="font-invoice-txt"> Ref. {{invoiceRef}}</span>
            </div>
        </div>
        <div class="row1" *ngIf ="userInfo" >
            <div class="font-txt-title">
                To: <span> Xiaomi</span>
            </div>
            <div class="font-txt">
                Dubai, United Arab Emirates
            </div>
            <div class="font-txt-title">
                Attn: <span>{{userInfo['a.first_name']}} {{userInfo['a.last_name']}}</span>
            </div>
            <div class="font-txt">
                {{userInfo['a.country']}} {{userInfo['a.city']}}
            </div>
            <div class="font-txt">
                Mobile: {{userInfo['a.phone']}}
            </div>
            <div class="font-txt">
                Email: {{userInfo['a.email']}}
            </div>
        </div>

        <div class="row2">
            Subject: Translation Services - Standard Invoice
        </div>

        <div class="row3">
            <div class="div-round">
                <div class="top-round">
                    No
                </div>
                <div class="bottom-round">
                    1
                </div>
            </div>
            <div class="div-tbl-header">
                <div class="div-col-1">
                    <span class="spn-header"> Item Description</span>
                </div>
                <div class="div-col-2">
                    <span class="spn-header">  Count Word</span>
                </div>
                <div class="div-col-3">
                    <span class="spn-header"> Estimatid time complete</span>
                </div>
                <div class="div-col-2">
                    <span class="spn-header">Total</span>
                </div>
            </div>
            <div *ngIf ="projectData" class="div-tbl-row">
                <div class="div-col-1">
                    <span class="spn-txt"> {{projectData.projectName}} </span>
                </div>
                <div class="div-col-2">
                    <span class="spn-txt">  {{projectData.sourceWordCount}}</span>
                </div>
                <div class="div-col-3">
                    <span class="spn-txt">{{translationTime}} Hours</span>
                </div>
                <div class="div-col-2">
                    <span class="spn-txt">AED {{totalPriceWithVat.toFixed(2).toString()}}</span>
                </div>
            </div>
        </div>

        <div class="row4">
            you received email with invoice
        </div>
        <div *ngIf = "internalUserRole != 'Corporate (Premium)'" class="row5">
            <div class="btn-quote">
                <button class="btn-cancel btn" mat-dialog-close >Cancel</button>

                <button class="btn-pay btn" (click)="performPayOperation('pay')"
                    >Pay Now</button>
            </div>
        </div>
        <div *ngIf = "internalUserRole == 'Corporate (Premium)'" class="row5">
            <div class="btn-quote">
                <button class="btn-cancel btn" (click) ="submitLater()" >Submit Later</button>
                <button class="btn-pay btn" (click)="changeProjectStatus()"
                    >Submit</button>
            </div>
        </div>

        <!-- <div class="w-100" class="d-flex justify-content-center">
            <span mat-dialog-title class="h3 font-weight-bold">Project Quote</span>
        </div>

        <div class="d-flex justify-content-center">
            <span class="font-weight-bold">WORDS - {{projectData.sourceWordCount}}</span>
        </div>

        <div class="d-flex justify-content-center">
            <span *ngIf="this.costCalculationFlag" class="orange font-weight-bold">
                COST - {{projectData.fee}} AED</span>
        </div>
        <div class="d-flex justify-content-center">
            <span *ngIf="translationTime" class="font-weight-bold">TIME - {{translationTime}} HRS</span>
            <span *ngIf="!(this.costCalculationFlag)" class="txt-color-red">
                <br /> Error in cost calculation check language & word count.</span>
        </div>

        <div *ngIf="dlService.showPayButton()">
            <div class="btn-quote">
                <button  mat-raised-button color="primary" style="width: 190px;" (click)="performPayOperation('draft')"
                    >Save Project as Draft</button>
            </div>
            <div class="btn-quote">
                <button mat-raised-button color="primary" style="width: 190px;" (click)="performPayOperation('pay')"
                    >Submit and
                    Pay Now</button>
            </div>
        </div>
        <div *ngIf="!dlService.showPayButton()" class="btn-quote">
            <button mat-raised-button color="primary" style="width: 190px;" mat-dialog-close
                (click)="performPayOperation('complete')" >Submit</button>
        </div> -->
    </div>
</ng-container>