<h2 mat-dialog-title >Specialized Translation Project
    <img src="/assets/icon-png/cross-icon-1.png" mat-dialog-close style="width: 11px;float: right;"  >
</h2>

<ng-container [formGroup]="projectAdditionGroupForm" >
    <div class="w-100" style="margin-bottom:15px ;">
        <span class="dialog-subtitle">03. Additional Information</span>
    </div>

    <div class="w-100">
        <span class="input-label">Project Description</span>
        <mat-form-field appearance="outline" class="w-100">
            <textarea maxlength="500" matInput formControlName="description" rows="5" cols="50" autocomplete="off"
                placeholder="Enter Project Description"></textarea>
        </mat-form-field>
    </div>

    <div class="w-100">
        <span class="input-label">Notes</span>
        <mat-form-field appearance="outline" class="w-100">
            <textarea maxlength="500" matInput formControlName="notes" rows="5" cols="50" autocomplete="off"
                placeholder="Enter Notes"></textarea>
        </mat-form-field>
    </div>
</ng-container>
<mat-dialog-actions align="end">
    <button mat-stroked-button mat-button  mat-dialog-close color="primary" (click)="callBack()">Back</button>
    <!-- <button mat-stroked-button mat-button  mat-dialog-close color="primary" (click)="callSkip()">Skip</button> -->
    <button mat-raised-button color="primary" mat-dialog-close cdkFocusInitial [disabled]="!projectAdditionGroupForm.valid" (click)="gotoNext()">Next</button>
</mat-dialog-actions>