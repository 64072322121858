import { Component, OnInit, OnChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DlServiceService } from 'src/app/dl-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {
  activeLink: string = '';
  constructor(private router: Router,
    private dlServiceService: DlServiceService) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.activeLink = this.router.url;
    });
  }

  links = [];
  ngOnInit(): void {
    this.setActiveProjectTab();
    this.setProjectLink();
  }

  setActiveProjectTab(): void {
    this.activeLink = this.router.url;
    // if (this.activeLink.includes('/project')) {
    //   this.activeLink = 'dashboard';
    // }
  }

  setProjectLink(): void {
    if (this.dlServiceService.showCustomerService() ||  this.dlServiceService.showProjectManager() ||  this.dlServiceService.showBackOfficeWorkers()) {
      this.links = [
        { name: "Pending", sicon: 'assets/icon-png/proj-menu-new-S.png', icon: 'assets/icon-png/proj-menu-new.png', link: 'project/list/new' ,linkDetails: 'project/new/details' },
        { name: "In Progress", sicon: 'assets/icon-png/proj-menu-inprogress-S.png', icon: 'assets/icon-png/proj-menu-inprogress.png', link: 'project/list/inprogress' ,linkDetails: 'project/inprogress/details' },
        { name: "Completed", sicon: 'assets/icon-png/proj-menu-complete-S.png', icon: 'assets/icon-png/proj-menu-complete.png', link: 'project/list/complete' ,linkDetails: 'project/complete/details' },
        { name: "Closed", sicon: 'assets/icon-png/proj-menu-close-S.png', icon: 'assets/icon-png/proj-menu-close.png', link: 'project/list/history' ,linkDetails: 'project/history/details' },
      ];
    } else if (this.dlServiceService.showTranslatortManager()) {
      this.links =  [
        { name: "Pending", sicon: 'assets/icon-png/proj-menu-new-S.png', icon: 'assets/icon-png/proj-menu-new.png', link: 'project/list/new' ,linkDetails: 'project/new/details' },
        { name: "In Progress", sicon: 'assets/icon-png/proj-menu-inprogress-S.png', icon: 'assets/icon-png/proj-menu-inprogress.png', link: 'project/list/inprogress' ,linkDetails: 'project/inprogress/details' },
        { name: "Review", sicon: 'assets/icon-png/proj-menu-review.png', icon: 'assets/icon-png/proj-menu-inprogress.png', link: 'project/list/review' ,linkDetails: 'project/review/details' },
        { name: "Completed", sicon: 'assets/icon-png/proj-menu-complete-S.png', icon: 'assets/icon-png/proj-menu-complete.png', link: 'project/list/complete' ,linkDetails: 'project/complete/details' },
        { name: "Closed", sicon: 'assets/icon-png/proj-menu-close-S.png', icon: 'assets/icon-png/proj-menu-close.png', link: 'project/list/history' ,linkDetails: 'project/history/details' },
      ];
    } else if (this.dlServiceService.showCustomer()) {
      this.links = [
        { name: "New", sicon: 'assets/icon-png/proj-menu-new-S.png', icon: 'assets/icon-png/proj-menu-new.png', link: 'project/list/new' ,linkDetails: 'project/new/details' },
        { name: "In Progress", sicon: 'assets/icon-png/proj-menu-inprogress-S.png', icon: 'assets/icon-png/proj-menu-inprogress.png', link: 'project/list/inprogress' ,linkDetails: 'project/inprogress/details' },
        { name: "Completed", sicon: 'assets/icon-png/proj-menu-complete-S.png', icon: 'assets/icon-png/proj-menu-complete.png', link: 'project/list/complete' ,linkDetails: 'project/complete/details' },
        { name: "Closed", sicon: 'assets/icon-png/proj-menu-close-S.png', icon: 'assets/icon-png/proj-menu-close.png', link: 'project/list/history' ,linkDetails: 'project/history/details' },
        { name: "All Project", sicon: 'assets/icon-png/proj-menu-all-S.png', icon: 'assets/icon-png/proj-menu-all.png', link: 'project/list/all' ,linkDetails: 'project/all/details' }

      ];
    }
    // else if (this.dlServiceService.showBackOffice()) {
    //   this.links = [
    //     { name: "New", sicon: 'assets/icon-png/proj-menu-new-S.png', icon: 'assets/icon-png/proj-menu-new.png', link: 'project/list/new' ,linkDetails: 'project/new/details' },
    //     { name: "In Progress", sicon: 'assets/icon-png/proj-menu-inprogress-S.png', icon: 'assets/icon-png/proj-menu-inprogress.png', link: 'project/list/inprogress' ,linkDetails: 'project/inprogress/details' },
    //     { name: "Completed", sicon: 'assets/icon-png/proj-menu-complete-S.png', icon: 'assets/icon-png/proj-menu-complete.png', link: 'project/list/complete' ,linkDetails: 'project/complete/details' },
    //     { name: "Closed", sicon: 'assets/icon-png/proj-menu-close-S.png', icon: 'assets/icon-png/proj-menu-close.png', link: 'project/list/history' ,linkDetails: 'project/history/details' },
    //     { name: "All Project", sicon: 'assets/icon-png/proj-menu-all-S.png', icon: 'assets/icon-png/proj-menu-all.png', link: 'project/list/all' ,linkDetails: 'project/all/details' }
    //   ];
    // }
  }
  ngOnChanges(): void {

  }


}
