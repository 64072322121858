import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DlServiceService } from 'src/app/dl-service.service';
import { LoaderService } from 'src/app/loader/loader.service';
import { ErrorMessageComponent } from 'src/app/shared/error-message/error-message.component';
import { Location } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { MessageBoxComponent } from 'src/app/shared/message-box/message-box.component';
import { AuthApiServiceService } from 'src/app/auth/auth-api-service.service';
import * as uuid from 'uuid';
import MD5 from 'crypto-js/md5';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/user/user.service';
import { UserModel } from 'src/app/user/Models/user-model';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { ProjectService } from 'src/app/project/project.service';
import { ProjectInfoComponent } from '../project-info/project-info.component';

@Component({
  selector: 'app-signup-user',
  templateUrl: './signup-user.component.html',
  styleUrls: ['./signup-user.component.scss'],
  animations: [
    trigger('toggleBox', [
      state(
        'closed',
        style({
          opacity: 0,
          height: '0px'
        })
      ),
      state(
        'open',
        style({
          opacity: 1,
          height: '75px'
        })
      ),
      // transition('open => closed', [
      //   animate('1s')
      // ]),
      // transition('closed => open', [
      //   animate('1s')
      // ]),
      transition('* => *', animate(1000)),
    ]),
  ]
})
export class SignupUserComponent implements OnInit {

  public parentAccountList: string[];
  public companyId: string;
  public companyName: string;
  public countries = [];
  public cites = [];
  public ngUnsubscribe = new Subject();
  public loadSpinnerFile: any;
  public accountType: string = "Individual";
  flagToggleBox = 'closed';
  constructor(
    private authApiService: AuthApiServiceService,
    private userService: UserService,
    private projectService: ProjectService,
    private readonly loaderService: LoaderService,
    private dialog: MatDialog,
    private dlService: DlServiceService,
    private location: Location,
  ) { }

  ngOnInit(): void {
    this.countries = this.dlService.getContries();
  }

  backToList(): void {
    this.location.back();
  }

  accountChangeEvent(_accountType: any) {
    if (_accountType.index === 0) {
      this.accountType = 'Individual';
      this.flagToggleBox = 'closed';
    } else {
      this.accountType = 'Corporate';
      this.flagToggleBox = 'open';
    }
  }

  bindCity(_country: any): any {
    this.cites = this.dlService.getCities(_country)
  }
  userForm: FormGroup = new FormGroup({
    companyName: new FormControl("", [Validators.pattern("^[a-zA-Z -']+"),]),
    firstName: new FormControl("", [Validators.required, Validators.pattern("^[a-zA-Z -']+"),]),
    lastName: new FormControl("", [Validators.required, Validators.pattern("^[a-zA-Z -']+"),]),
    email: new FormControl("", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),]),
    userType: new FormControl({ value: 'Individual', disabled: true }, [Validators.required]),
    country: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    // phoneNumber: new FormControl('', Validators.required),
  });

  public saveUser(): void {
    if (this.userForm.valid) {
      const userModel: UserModel = this.userForm.value;
      userModel["account_id"] = this.companyId;
      userModel["internalRole"] = ['Corporate'];
      userModel["userType"] = 'Customer';
      const signupPayload = {
        user: {
          country: userModel.country,
          api_user_only: 0,
          first_name: userModel.firstName,
          city: userModel.city,
          last_name: userModel.lastName,
          email: userModel.email.toLowerCase(),
          host: window.location.origin + '/auth/activateUser',
          password: MD5(uuid.v4()).toString(),
          role: 'User',
          username: userModel.email.toLowerCase(),
          //send_email: true //Send activate password email
          send_email: false //Send reset password email
        },
        account: {
          name: userModel.email.toLowerCase(),
          parent_id: environment.customerAccountId,
          password_policy: '.',
        },
        country_code: this.dlService.getCountryCode(userModel.country),
        internal_user_role: 'Individual'
      };
      this.signupWithFoundry(signupPayload);
    }
  }

  private signupWithFoundry(signupPayload): void {
    const loaderSubscription: Subscription = this.loaderService.spinner$.subscribe();
    this.authApiService
      .signup(signupPayload)
      // .pipe(
      //   takeWhile(() => !this.ngUnsubscribe),
      //   finalize(() => loaderSubscription.unsubscribe())
      // )
      .subscribe(
        (response: any) => {
          console.log('response', response);
          if (response.errorMessage) {
            const err = JSON.parse(response.errorMessage);
            loaderSubscription.unsubscribe();
            this.showErrorMessage(err.error ? err.error : 'Error in user Add');
          } else {
            let userList = this.userService.getUserData()
            if (userList) {
              userList.push(response.userInfo);
            } else {
              userList = [];
              userList.push(response.userInfo)
            }
            this.userService.setUserData(userList);
            this.dlService._userDevices$.next('Refersh data');

            let projData = this.projectService.getProjectBlank();
            // this.dlService.setSelectedAccountId(formData.companyId);
            projData.companyId = response.userInfo['a.account_ref'];
            projData.companyName = response.userInfo['a.account_ref'];
            projData.userInfo = response.userInfo;
            this.projectService.setCreateProjectInfo(projData);
            this.dialog.open(ProjectInfoComponent, {
              width: '910px',
              disableClose: true
            });

            loaderSubscription.unsubscribe();


            // this.dialog.open(MessageBoxComponent, {
            //   width: '500px',
            //   data: {
            //     imgIcon: '/assets/icon-png/proj-success.png',
            //     header: 'User Added Successfully.',
            //     sub_title: 'Ask The Member To Check His Email.',
            //     redirect: '/users/individual'
            //   }
            // });
          }
        },
        (err) => {
          loaderSubscription.unsubscribe();
          console.log('error', err.error?.error || err.error || err);
          this.showErrorMessage(err.error?.error || err.error || err);

        }
      );
  }

  showErrorMessage(message: any): void {
    this.dialog.open(ErrorMessageComponent, {
      width: '500px',
      data: {
        'title': 'Error In User Add',
        'description': message,
        'redirect': '/users/individual'
      }
    });
  }
}
