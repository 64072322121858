<mat-card class="shadow p-3 p-sm-5">
  <mat-card-header>
    <mat-card-title>Create New Account</mat-card-title>
    <mat-card-subtitle>
      <img class="cls-img" src="assets/icon-png/logo-big.png" />
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <mat-tab-group (selectedTabChange)="accountChangeEvent($event)" animationDuration="0ms">
      <mat-tab style="opacity:1 ;" label="Individual">
        <ng-template mat-tab-label>
          <div>
            <img *ngIf="accountType === 'Individual'" style="margin-bottom: 3px;" src="assets/icon-png/user-icon-blue.png" />
            <img *ngIf="accountType !== 'Individual'" style="margin-bottom: 3px;" src="assets/icon-png/signup-i-n.png" />
            <span
              [ngClass]="{ 'tab-select': accountType === 'Individual', 'tab-unselect': accountType !== 'Individual' }">
              Individual
            </span>
          </div>
        </ng-template>
      </mat-tab>

      <mat-tab label="Corporate">
        <ng-template mat-tab-label>
          <div>
            <img *ngIf="accountType === 'Individual'" src="assets/icon-png/sign-c-n.png" />
            <img *ngIf="accountType !== 'Individual'" src="assets/icon-png/coporate-icon-grey.png" />
            <span
              [ngClass]="{ 'tab-select': accountType !== 'Individual', 'tab-unselect': accountType === 'Individual' }">
              Corporate </span>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>

  <form (submit)="signup()" [formGroup]="signupForm" autocomplete="off">
    <mat-card-content>
      <span class="font-weight-bold" *ngIf="accountType != 'Individual'">Organization Name</span>
      <mat-form-field appearance="outline" class="w-100" *ngIf="accountType != 'Individual'">
        <mat-icon fontSet="fa" fontIcon="fa-building" matPrefix *ngIf="!getControl('companyName').value"
          aria-hidden="true"></mat-icon>
        <input matInput maxlength="30" placeholder="Enter organaization name" formControlName="companyName">
      </mat-form-field>

      <div class="w-100 d-flex flex-md-row flex-column justify-content-between">
        <div class="col p-0 pr-md-1">
          <span class="font-weight-bold">First Name</span>
          <mat-form-field appearance="outline" class="w-100">
            <mat-icon style="background-image: url('assets/icon-png/user-icon.png');height: 15px;" matPrefix
              *ngIf="!getControl('firstName').value" aria-hidden="true"></mat-icon>
            <input maxlength="15" matInput placeholder="Enter first name" formControlName="firstName" required>
          </mat-form-field>
        </div>

        <div class="col p-0 pl-md-1">
          <span class="font-weight-bold">Last Name</span>
          <mat-form-field appearance="outline" class="w-100">
            <mat-icon style="background-image: url('assets/icon-png/user-icon.png');height: 15px;" matPrefix
              *ngIf="!getControl('lastName').value" aria-hidden="true"></mat-icon>
            <input maxlength="15" matInput placeholder="Enter last name" formControlName="lastName" required>
          </mat-form-field>
        </div>
      </div>

      <span class="font-weight-bold">Country</span>
      <mat-form-field  appearance="outline" class="w-100">
        <mat-icon style="background-image: url('assets/icon-png/country-icon.png');height: 13px;" matPrefix
          *ngIf="!getControl('country').value" aria-hidden="true">
        </mat-icon>
        <mat-select (selectionChange)="bindCity($event.value)" MatFormFieldControl formControlName="country" placeholder="Select your country">
          <mat-option *ngFor="let country of countries" [value]="country">
            {{country}} </mat-option>
      </mat-select>
      </mat-form-field>

      <span class="font-weight-bold">City</span>
      <mat-form-field appearance="outline" class="w-100">
        <mat-icon style="background-image: url('assets/icon-png/city-icon.png');height: 18px;" matPrefix
          *ngIf="!getControl('city').value" aria-hidden="true">
        </mat-icon>
        <mat-select MatFormFieldControl formControlName="city" placeholder="Select your city">
          <mat-option *ngFor="let city of cites" [value]="city">
            {{city}} </mat-option>
      </mat-select>
      </mat-form-field>

      <span class="font-weight-bold">Email</span>
      <mat-form-field appearance="outline" class="w-100">
        <mat-icon style="background-image: url('assets/icon-png/email-icon.png');height: 13px;" matPrefix
          *ngIf="!getControl('email').value" aria-hidden="true">
        </mat-icon>
        <input maxlength="40" matInput placeholder="Enter your Email" formControlName="email" required>
      </mat-form-field>

      <span class="font-weight-bold">Password</span>
      <mat-form-field appearance="outline" class="w-100">
        <mat-icon style="background-image: url('assets/icon-png/password-icon.png');height: 17px;" matPrefix
          *ngIf="!getControl('password').value" aria-hidden="true">
        </mat-icon>
        <input matInput maxlength="30" type="password" placeholder="Enter Password" formControlName="password" required>
      </mat-form-field>

      <span class="font-weight-bold">Confirm Password</span>
      <mat-form-field appearance="outline" class="w-100">
        <mat-icon style="background-image: url('assets/icon-png/password-icon.png');height: 17px;" matPrefix
          *ngIf="!getControl('confirmPassword').value" aria-hidden="true"></mat-icon>
        <input maxlength="30" matInput type="password" placeholder="Confirm Password" formControlName="confirmPassword" required>
      </mat-form-field>
    </mat-card-content>

    <button mat-raised-button color="primary" class="w-100"
      [disabled]="!signupForm.valid || signupInProgress || (!(accountType !== 'Corporate' || getControl('companyName').value.trim().length > 0))">
      Sign Up
    </button>
    <mat-card-content>
      <div class="row justify-content-center mt-4">
        <div class="float-left"
          style="height: 1px; width: 40%; background-color: rgb(207, 207, 207); padding-top: 1px; margin-top:  14px;">
        </div>
        <div class="border rounded-circle p-2">
          OR
        </div>
        <div class="folat-right"
          style="height: 1px; width: 40%; background-color: rgb(207, 207, 207); padding-top: 1px; margin-top:  14px;">

        </div>
      </div>
    </mat-card-content>
    <span class="spn-center login-text" >
      Already have an account? 
      <button class="login-link" routerLink="/auth/login"
          routerLinkActive="active">Login</button>
  </span>
    <!-- <button mat-raised-button color="primary" class="w-100" routerLink="auth/login">
      Log In
    </button> -->
  </form>
</mat-card>