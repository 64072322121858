import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ProjectModel } from 'src/app/project/Models/project-model';
import { ProjectService } from 'src/app/project/project.service';
import { ProjectConfirmationComponent } from '../project-confirmation/project-confirmation.component';
import { ProjectFileUploadComponent } from '../project-file-upload/project-file-upload.component';

@Component({
  selector: 'app-project-addition-info',
  templateUrl: './project-addition-info.component.html',
  styleUrls: ['./project-addition-info.component.scss']
})
export class ProjectAdditionInfoComponent implements OnInit {
  public projectData: ProjectModel;

  constructor(private dialog: MatDialog,
    private projectService: ProjectService) {
  }

  ngOnInit(): void {
    this.projectData = this.projectService.getCreateProjectInfo();
    this.projectAdditionGroupForm.patchValue({
      description: this.projectData.description,
      notes: this.projectData.notes
    })
  }
  public projectAdditionGroupForm = new FormGroup({
    description: new FormControl('', [Validators.required]),
    notes: new FormControl('', [Validators.required])
  }); 

  gotoNext(): void {
    this.projectData["description"] = this.projectAdditionGroupForm.value.description;
    this.projectData["notes"] = this.projectAdditionGroupForm.value.notes;
    this.projectService.setCreateProjectInfo(this.projectData);
    this.dialog.open(ProjectConfirmationComponent, {
      width: '650px',
      disableClose: true
    });

  }
  callBack(): void {
    this.dialog.open(ProjectFileUploadComponent, {
      width: '750px',
      disableClose: true
    });
  }
}
