import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import MD5 from 'crypto-js/md5';
import { Subscription } from "rxjs";
import { finalize, takeWhile } from "rxjs/operators";
import { Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { environment } from "../../../environments/environment";
import { LoaderService } from "../../loader/loader.service";
import { AuthApiServiceService } from '../auth-api-service.service';
import { DlServiceService } from 'src/app/dl-service.service';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class SignupComponent implements OnInit, OnDestroy {
    public countries = [];
    public cites = [];
    public selectedCountryCode = '';
    private ngUnsubscribe = false;
    signupInProgress = false;
    public accountType: string = "Individual";
    accountChangeEvent(_accountType: any) {
        if (_accountType.index === 0) {
            this.accountType = 'Individual';
        } else {
            this.accountType = 'Corporate';
        }
    }

    signupForm: FormGroup = new FormGroup(
        {
            companyName: new FormControl("", [Validators.pattern("^[a-zA-Z -']+"),]),
            firstName: new FormControl("", [Validators.required, Validators.pattern("^[a-zA-Z -']+"),]),
            lastName: new FormControl("", [Validators.required, Validators.pattern("^[a-zA-Z -']+"),]),
            city: new FormControl('', [Validators.required]),
            country: new FormControl('', [Validators.required]),
            email: new FormControl("", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),]),
            password: new FormControl('', [Validators.required]),
            confirmPassword: new FormControl('', [Validators.required]),
        },
        [
            (g: FormGroup) => {
                if (g.get('confirmPassword').pristine) {
                    return null;
                }
                return g.get('password').value ===
                    g.get('confirmPassword').value
                    ? null
                    : { mismatch: true };
            },
        ]
    );
    constructor(
        private dlServices: DlServiceService,
        private authApiService: AuthApiServiceService,
        private router: Router,
        private readonly notifier: NotifierService,
        private readonly loaderService: LoaderService
    ) {
    }

    ngOnInit() {
        this.countries = this.dlServices.getContries();
    }

    bindCity(_country): any {
        this.cites = this.dlServices.getCities(_country)
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe = true;
    }

    getControl(controlName: string): FormControl {
        return this.signupForm.controls[controlName] as FormControl;
    }
    signup(): void {
        this.signupForm
            .get('firstName')
            .setValue(this.signupForm.get('firstName').value.trim());
        this.signupForm
            .get('city')
            .setValue(this.signupForm.get('city').value.trim());
        this.signupForm
        this.signupForm
            .get('lastName')
            .setValue(this.signupForm.get('lastName').value.trim());
        this.signupForm
            .get('password')
            .setValue(this.signupForm.get('password').value.trim());
        this.signupForm
            .get('confirmPassword')
            .setValue(this.signupForm.get('confirmPassword').value.trim());
        this.signupForm
            .get('companyName')
            .setValue(this.signupForm.get('companyName').value.trim());

        const signupVal = this.signupForm.getRawValue();
        const cName = (this.accountType == "Individual") ? signupVal.email.toLowerCase() : signupVal.companyName;

        if (this.signupForm.valid && cName) {
            const signupPayload = {
                user: {
                    country: signupVal.country,
                    api_user_only: 0,
                    first_name: signupVal.firstName,
                    city: signupVal.city,
                    last_name: signupVal.lastName,
                    email: signupVal.email.toLowerCase(),
                    host: window.location.origin + '/auth/activateUser',
                    password: MD5(signupVal.password).toString(),
                    role: 'User',
                    username: signupVal.email.toLowerCase(),
                    send_email: true //Send activate password email
                    //send_email : false //Send reset password email
                },
                account: {
                    name: cName,
                    parent_id: environment.customerAccountId,
                    password_policy: '.',
                },
                country_code: this.dlServices.getCountryCode(signupVal.country),
                internal_user_role: this.accountType
            };

            this.signupInProgress = true;
            this.signupWithFoundry(signupPayload);
        }
    }

    private signupWithFoundry(signupPayload): void {
        this.notifier.notify('success', 'Creating account...');
        const loaderSubscription: Subscription = this.loaderService.spinner$.subscribe();
        this.authApiService
            .signup(signupPayload)
            .pipe(
                takeWhile(() => !this.ngUnsubscribe),
                finalize(() => loaderSubscription.unsubscribe())
            )
            .subscribe(
                (response: any) => {
                    if (response.errorMessage) {
                        const err = JSON.parse(response.errorMessage);
                        this.signupInProgress = false;
                        console.error(err.error);
                        this.notifier.notify('error', err.error);
                    } else {
                        this.notifier.notify('success', `User activation link sent to ${response.user.email}. Redirecting you now...`);
                        setTimeout(() => {
                            this.signupInProgress = false;
                            this.router.navigate(['']);
                        }, 3000);
                    }

                },
                (err) => {
                    this.signupInProgress = false;
                    console.error(err);
                    this.notifier.notify('error', err.error?.error || err.error || err);
                }
            );
    }

}