import { Component } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { SplashScreenService } from "./splash-screen/splash-screen.service";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  private unsubscribe: Subscription[] = [];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private splashScreenService: SplashScreenService,
    private router: Router,
  ) {
    this.matIconRegistry.addSvgIcon(
      `arrow-d-r`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/arrow-d-r.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `arrow-d-l`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/arrow-d-l.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `proj-in-progress`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/dl-icons/proj-in-progress.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `proj-complete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/dl-icons/proj-complete.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `proj-history`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/dl-icons/proj-history.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `proj-new`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/dl-icons/proj-new.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `proj-not-assign`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/dl-icons/proj-not-assign.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `proj-payment-pending`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/dl-icons/proj-payment-pending.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `proj-dashboard-add`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/dl-icons/proj-dashboard-add.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `proj-dashboard-contain`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/dl-icons/proj-dashboard-contain.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `proj-dashboard-edit-pre`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/dl-icons/proj-dashboard-edit-pre.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `proj-dashboard-location`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/dl-icons/proj-dashboard-location.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `proj-dashboard-subtiling`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/dl-icons/proj-dashboard-subtiling.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `proj-dashboard-vector`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/dl-icons/proj-dashboard-vector.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `folder-add-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/dl-icons/folder-add.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `folder-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/dl-icons/folder-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `report-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/report-icon.svg`)
    );

    // this.matIconRegistry.addSvgIcon(
    //     `article`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/article.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `back-end-integration`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/back-end-integration.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `chart-pie`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/chart-pie.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `enter-user`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/enter-user.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `grid`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/grid.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `network-connection`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/network-connection.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `smart-things`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/smart-things.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `wordpress-simple`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/wordpress-simple.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `ml-engine`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/ml-engine.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `search`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/search.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `google`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/google.svg`)
    // );


    // this.matIconRegistry.addSvgIcon(
    //     `chats`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/chats.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `charts`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/charts.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `replace`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/replace.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `alexa`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/alexa.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `amazon-chime`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/amazon-chime.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `f-messenger`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/f-messenger.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `message`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/message.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `mobile`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/mobile.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `slack`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/slack.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `whatsapp`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/whatsapp.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `magneto`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/magneto.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `retailak-logo`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/retailak-logo.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `salesforce`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/salesforce.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `sap`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/sap.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `servicenow`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/servicenow.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `sharepoint`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/sharepoint.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `shopify`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/shopify.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `wordpress-logo`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/wordpress-logo.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `kindra`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/kindra.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `google-translate`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/google-translate.svg`)
    // );
    // this.matIconRegistry.addSvgIcon(
    //     `azure-cognitive`,
    //     this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/azure-cognitive.svg`)
    // );
  }

  ngOnInit(): void {
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        const newLocal = 500;
        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, newLocal);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
