<ng-container>
    <div class="div-dialog">
        <div class="row-1 icon-proj">
            <img [src]="fileData['imgIcon']">
        </div>
        <div *ngIf="fileData['title']" class="row-1 txt-1">
            {{fileData['title'] }}
        </div>
        <div *ngIf="fileData['header']" class="row-1 txt-01">
            {{fileData['header'] }}
        </div>
        <div *ngIf="fileData['sub_title']" class="row-1 txt-2">
            {{fileData['sub_title'] }}
        </div>
        <div *ngIf="fileData['sub_title_2']" class="row-1 txt-2_1">
            {{fileData['sub_title_2'] }}
        </div>

        <div *ngIf="fileData['csProj']" class="row-1 txt-4">
          <strong> Thank You For Submission.</strong> 
        </div>
        
        <div *ngIf="fileData['csProj']" class="row-1 txt-4">
            the User/Company Should receve an Email
        </div>
        <div *ngIf="fileData['csProj']" class="row-1 txt-4">
            You Can See the Project On your 
            <span style="color: #2C6231;"> Report </span>
        </div>

        <div class="row-1">

            <button *ngIf="routeRedirect" routerLink="{{routeRedirect}}" mat-dialog-close class="btn-ok">Ok</button>
            <button *ngIf="!routeRedirect" mat-dialog-close class="btn-ok">Ok</button>
        </div>
    </div>
</ng-container>