export enum projectInternalStatus {
    new = 'New',
    progress_pm = 'In Progress (PM)',
    progress_tm = 'In Progress (TM)',
    progress_t = 'In Progress (T)',
    progress_pr = 'In Progress (PR)',
    progress_qa = 'In Progress (QA)',
    complete = 'Complete',
    close = 'Closed'
  }
  export enum enumProjectStatus {
    new = 'New',
    progress= 'In-progress',
    complete = 'Complete',
    close = 'Closed'
  }
  export enum enumUserRole {
    csManager = 'Customer Service',
    projManager = 'Project Manager',
    projTranlatorManager = 'Translator Manager',
    translator = 'Translator',
    proofReader = 'ProofReader',
    auditor = 'Auditor',
    individual = 'Individual',
    corporate = 'Corporate',
    corporatePremium = 'Corporate (Premium)'
  }

  export enum enumAssignStatus {
    new = 'New',
    accept = 'Inprogress',
    decline = 'Decline',
    complete = 'Complete',
  }