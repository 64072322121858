import { Component, OnInit } from '@angular/core';
import { ProjectModel } from 'src/app/project/Models/project-model';
import { ProjectService } from 'src/app/project/project.service';
import { v1 } from 'uuid';
import { environment } from 'src/environments/environment';
import { AuthService } from '@thinglogix/ngx-foundry-core';
import { combineLatest, Subscription } from 'rxjs';
import { LoaderService } from 'src/app/loader/loader.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DlServiceService } from 'src/app/dl-service.service';
import * as moment from 'moment';
import { MessageBoxComponent } from 'src/app/shared/message-box/message-box.component';
import { enumUserRole } from 'src/app/util-enum';

@Component({
  selector: 'app-project-qute',
  templateUrl: './project-qute.component.html',
  styleUrls: ['./project-qute.component.scss']
})
export class ProjectQuteComponent implements OnInit {
  public projectSaved: any;
  public projectData: ProjectModel;
  public userInfo: any;
  public invoiceRef = '';
  public isGettingCheckout = false;

  public loaderSubscription: Subscription;
  public totalPrice: any = 0;
  public vat: any = 0;
  public totalPriceWithVat: any = 0;
  public translationTime: any = 0;
  public internalUserRole = '';

  constructor(
    private dialog: MatDialog,
    private projectService: ProjectService,
    private authService: AuthService,
    public dlService: DlServiceService,
    private readonly loaderService: LoaderService,
    public dialogRef: MatDialogRef<ProjectQuteComponent>,
  ) { }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.internalUserRole = this.userInfo['a.internal_user_role'].toString();
    this.projectData = this.projectService.getCreateProjectInfo();
    if (!this.projectData.invoiceRef) {
      this.getInvoiceRef();
    } else {
      this.invoiceRef = this.projectData.invoiceRef;
      this.calculatePriceNtime();
    }

  }

  getInvoiceRef(): void {
    this.loaderSubscription = this.loaderService.spinner$.subscribe();
    this.dlService.getObjectsByType(environment.JobCountTypeID).subscribe((data: any) => {
      let jobData = data;
      if (data && data.length > 0) {
        jobData = data.filter(m => m['a.job_date'] === moment().format('YYMMDD'));
      }
      // set invoice ref and update
      if (jobData && jobData.length > 0) {
        const count = parseInt(jobData[0]['a.count'], 10) + 1;
        const jobPayload = {
          'a.count': count,
        };
        this.projectData.invoiceRef = 'AMQ' + moment().format('YYMMDD') + count.toString();
        this.invoiceRef = this.projectData.invoiceRef;
        this.dlService.updateObject(jobData[0]['deviceId'], jobPayload).subscribe();
        this.loaderSubscription.unsubscribe();
        this.calculatePriceNtime();
      }
      else {
        this.projectData.invoiceRef = 'AMQ' + moment().format('YYMMDD') + '1';
        this.invoiceRef = this.projectData.invoiceRef;
        const jobPayload = {
          deviceId: v1(),
          typeId: environment.JobCountTypeID,
          accountId: this.authService.user.account_id,
          isParent: false,
          created_by: this.authService.user.id,
          'a.count': 1,
          'a.job_date': moment().format('YYMMDD')
        };
        this.dlService.createObject(jobPayload).subscribe();
        this.loaderSubscription.unsubscribe();
        this.calculatePriceNtime();
      }
    })
  }

  calculatePriceNtime(): void {
    // Price Calculation
    const translatePrice = parseFloat(this.projectData.pricePerWord.toString()) * parseFloat(this.projectData.sourceWordCount.toString());
    const pageFormatPrice = parseFloat(this.projectData.priceFileFormat.toString()) * parseFloat(this.projectData.sourcePageCount.toString());
    this.totalPrice = translatePrice + pageFormatPrice + parseFloat(this.projectData.priceDocType.toString());
    if (parseFloat(this.projectData.priceMinimum.toString()) > this.totalPrice) {
      this.totalPrice = this.projectData.priceMinimum;
    }

    this.vat = 5 * this.totalPrice / 100;
    this.totalPriceWithVat = parseFloat((this.totalPrice + this.vat).toFixed(2));

    //Time Calculation
    this.translationTime = parseFloat((parseFloat(this.projectData.timePerWord.toString()) * parseFloat(this.projectData.sourceWordCount.toString()) / 3600).toFixed(2));
    if (parseFloat(this.projectData.timeMinimum.toString()) > this.translationTime) {
      this.translationTime = this.projectData.timeMinimum;
    }

    this.saveProject();
  }

  saveProject(): void {
    this.loaderSubscription = this.loaderService.spinner$.subscribe();
    if (this.projectData) {
      const payload = {
        typeId: environment.projectTypeId,
        isParent: false,
        created_by: this.authService.user.id,
        accountId: this.authService.user.account_id,
        'a.name': this.projectData.projectName,
        'a.deliverable_language': this.projectData.targetLanguage,
        'a.source_language': this.projectData.sourceLanguage,
        'a.description': this.projectData.description,
        'a.source_files': this.projectData.fileName,
        'a.payment_status': 'unpaid',
        'a.status': "New",
        'a.source_word_count': this.projectData.sourceWordCount,
        'a.source_page_count': this.projectData.sourcePageCount,
        'a.notes': this.projectData.notes,

        'a.source_file_format': this.projectData.sourceFileFormat,
        'a.target_file_format': this.projectData.targetFileFormat,
        'a.invoice_ref': this.projectData.invoiceRef,
        'a.type_of_content': this.projectData.typeDoc,
        'a.translation_time': this.translationTime,
        'a.vat_cost': this.vat,
        'a.fee': this.totalPriceWithVat,
        'a.conversion_cost': this.totalPrice,
        'a.price_per_word': this.projectData.pricePerWord,
        'a.price_file_format': this.projectData.priceFileFormat,
        'a.price_doc_type': this.projectData.priceDocType,
        'shortId': 'a.project_count'
      };
      if (this.projectData.projectId) {
        this.dlService.updateObject(this.projectData.projectId, payload).subscribe((data) => {
          this.projectService.updateProjectInList(data);
          this.projectService._projectData$.next(true);
          this.projectSaved = data;
          this.loaderSubscription.unsubscribe();
        });

      } else {
        this.projectData.projectId = v1();
        payload['deviceId'] = this.projectData.projectId;
        payload['a.user_ref'] = this.authService.user.id;
        payload['a.contract_account_ref'] = this.authService.user.account_id;
        payload['a.username'] = this.authService.user.first_name + ' ' + this.authService.user.last_name;
        payload['a.internal_status'] = 'New';
        this.dlService.createObject(payload).subscribe((data: any) => {
          this.projectService.createProjectHistory(data, 'Upload by Customer', payload['a.user_ref']);
          this.projectSaved = data;
          this.projectService.sendNotification(this.projectSaved.deviceId, 'invoice', false).subscribe();
          this.projectService.addProjectInList(data);
          this.loaderSubscription.unsubscribe();
        });
      }
    }
  }

  performPayOperation(): void {
    this.loaderSubscription = this.loaderService.spinner$.subscribe();
    this.createNgPaymentSession(this.projectSaved);
  }

  submitLater(): void {
    this.dialogRef.close();
    this.dialog.open(MessageBoxComponent, {
      width: '500px',
      data: {
        imgIcon: '/assets/icon-png/proj-save.png',
        header: 'Project Saved to Submit Later',
        sub_title: 'Thank You For Adding Project',
        sub_title_2: 'You Can Find Your Project In The NEW Saved As A Draft',
        redirect: '/project/list/new'
      }
    });
  }
  changeProjectStatus(): void {
    const payload = {
      typeId: environment.projectTypeId,
      accountId: this.authService.user.account_id,
      'a.payment_status': 'paid',
      'a.status': 'In-progress'
    };
    this.loaderSubscription = this.loaderService.spinner$.subscribe();

    combineLatest([
      this.dlService.updateObject(this.projectData.projectId, payload),
      this.dlService.getObjectsByType(environment.userInfoTypeId)
    ]).subscribe((result: any) => {
      const updatedProj = result[0];
      this.projectService.sendNotification(this.projectData.projectId, 'payment premium', true).subscribe();
      const roleUser = result[1].filter((m: any) => m['a.internal_user_role'].includes(enumUserRole.csManager));
      let emailAddress = '';
      roleUser.forEach(element => {
        if (element['a.email']) {
          this.projectService.saveNotification(element.deviceId, updatedProj['a.invoice_ref'] + '-Please review the ' + updatedProj['a.name'] + ' project and assign to your team.', this.projectData.projectId);
          emailAddress += element['a.email'];
        }

      });
      this.projectService.sendNotificationMail(this.projectData.projectId, emailAddress, '', '', 'Project Assign CS').subscribe();


      this.projectService.updateProjectInList(result[0]);
      this.projectService._projectData$.next(true);
      this.projectSaved = result[0];
      this.loaderSubscription.unsubscribe();
      this.dialogRef.close();
      this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {
          imgIcon: '/assets/icon-png/proj-success.png',
          header: 'Project Submit Successfully',
          sub_title: 'Thank You For Submission',
          sub_title_2: 'Now Your Project Is In Progress',
          redirect: '/project/list/inprogress'
        }
      });

    });
  }

  createNgPaymentSession(projectDetails): void {
    this.isGettingCheckout = true;
    const totalAmount = projectDetails["a.fee"];
    this.projectService.createNgPaymentSession(projectDetails["a.name"], totalAmount, projectDetails["deviceId"]).subscribe(data => {
      if (data.session_id && data.session_id.payment_href) {
        window.location.href = data.session_id.payment_href;
      } else {
        this.isGettingCheckout = false;
        console.log('Error in NgPayment session create', data);
      }
    },
      error => {
        this.loaderSubscription.unsubscribe();
        this.isGettingCheckout = false;
        console.log('Error in stripe session create out', error);
      });
  }
}
