<h2 mat-dialog-title >Specialized Translation Project
    <img src="/assets/icon-png/cross-icon-1.png" mat-dialog-close style="width: 11px;float: right;"  >
</h2>

<ng-container>
    <div class="w-100"  style="margin-bottom:15px ;">
        <span class="dialog-subtitle">04. Info Confirmation</span>
    </div>

    <div class="w-100 mt-2">
        <span class="input-label">Project Title</span>
        <label class="label-data"> {{projectData.projectName}} </label>
    </div>

    <div class="w-50 mt-2 float-right">
        <span class="input-label">Target Language</span>
        <label class="label-data"> {{projectData.targetLanguage}} </label>
    </div>
    
    <div class="w-50 mt-2 float-right">
        <span class="input-label">Source Language</span>
        <label class="label-data"> {{projectData.sourceLanguage}} </label>
    </div>

    <div class="w-50 mt-2 float-right">
        <span class="input-label">Target File Type</span>
        <label class="label-data"> {{projectData.targetFileFormat}} </label>
    </div>
    
    <div class="w-50 mt-2 float-right">
        <span class="input-label">Source File Type</span>
        <label class="label-data"> {{projectData.sourceFileFormat}} </label>
    </div>

  

    <div class="w-50 mt-2 float-right">
        <span class="input-label">Number of Words</span>
        <label class="label-data"> {{projectData.sourceWordCount}} </label>
    </div>

    <div class="w-50 mt-2 float-right">
        <span class="input-label">Number of Pages</span>
        <label class="label-data"> {{projectData.sourcePageCount}} </label>
    </div>


    <div class="w-50 mt-2 float-right">
        <span class="input-label">Project Type</span>
        <label class="label-data"> {{projectData.typeDoc}} </label>
    </div>

    <div class="w-50 mt-2 float-right">
        <span class="input-label">File</span>
            <p matInput class="label-data" *ngFor="let file of projectData.fileName">
                {{file.fileName}}</p>    
    </div>
    
    <div class="w-50 mt-2 float-right">
        <span class="input-label">Project Description</span>
        <label class="label-data"> {{projectData.description}} </label>
    </div>
    
    <div class="w-50 mt-2 float-right">
        <span class="input-label">Notes</span>
        <label class="label-data"> {{projectData.notes}} </label>
    </div>


</ng-container>
<div class="row-1">
<mat-dialog-actions style="display:block;" align="end">
    <button mat-stroked-button mat-button color="primary" mat-dialog-close (click)="callBack()">Edit</button>
    <button mat-raised-button color="primary" cdkFocusInitial mat-dialog-close
        (click)="gotoNext()">Submit</button>
</mat-dialog-actions>
</div>
