<div class="header-container">
    <nav mat-tab-nav-bar mat-align-tabs="start" >
        <div *ngFor="let link of links">
            <a mat-tab-link (click)="activeLink = link.link" [routerLink]="link.link"
                [active]="activeLink.includes(link.link) || activeLink.includes(link.linkDetails)">
                <img style="padding-right: 11px" [src]="(activeLink.includes(link.link) || activeLink.includes(link.linkDetails)) ? link.sicon : link.icon" />
                
                {{link.name}}
            </a>
        </div>
    </nav>

</div>