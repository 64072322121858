import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {
  public routeRedirect:string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public fileData: any,
  ) { }

  ngOnInit(): void {
    this.routeRedirect = this.fileData.redirect
  }

}
