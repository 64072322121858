<div *ngIf="!uploadingFile && !showAnotherFile" class="container" appDnd (fileDropped)="onFileDropped($event)">
    <input type="file" #fileDropRef id="fileDropRef" accept="image/*,application/pdf,text/csv,application/vnd.ms-excel"
        multiple (change)="fileBrowseHandler($event.target.files)" />
    <h3>Drag and drop your here</h3>
    <h3>or</h3>
    <label for="fileDropRef">Browse files</label>
</div>
<div *ngIf="!(!uploadingFile && !showAnotherFile)" class="files-list">
    <div class="single-file">
        <div class="inner-div-box">
            <div class="row-file">
                <div class="file-icon">
                    <img style="height: 50px;" src="../../assets/icon-png/upload-file.png" />
                </div>
            </div>
            <div class="row-file" *ngFor="let file of filesInfo; let i = index">
                <h4 *ngIf="!uploadingFile" class="name" style="color:#292D34 ;">
                    {{ file.fileName}} <img src="../../assets/icon-png/green-sign.png" />
                </h4>
                <div class="pr-box"
                    *ngIf="uploadingFile && file.processColor!=='green'">
                    <div class="pr-bar">

                        <span class="pr-file"> {{ file.fileName}}
                            <span class="pr-data"> 33% </span>
                        </span>
                        <mat-progress-bar value="33" [appProgressBarColor]="file.processColor"
                            [mode]="file.processMode">
                        </mat-progress-bar>

                    </div>
                    <div class="pr-close">
                        <img class="pr-icon" src="../../assets/icon-png/file-cross.png" />
                    </div>
                </div>

            </div>

            <div class="row-file">
                <label (click)="showAnotherFile = false;" class="lnk-lable" for="fileDropRef">UPLOAD ANOTHER
                    FILE</label>
            </div>
            <!-- <div class="delete" (click)="deleteFile(i)">
            <img src="../../assets/icons/ic-delete-file.svg" />
        </div> -->
        </div>
    </div>
</div>